import { format } from "date-fns";

import { Route } from "./useDeliveryPlanner";

export const METERS_IN_MILE = 1609.34;

export const defaultRoute: Route = {
  id: 0,
  start_time: format(Date.now(), "yyyy-MM-dd") + "T09:00:00Z",
  start_location: "",
  end_location: "",
  orders: [],
  status: "new",
};
