interface Params {
  sortBy?: string;
  sortOrder?: "asc" | "desc";
  onSort?: (sortBy: string, sortOrder: "asc" | "desc") => any;
}

function useDataTable({ sortBy, sortOrder, onSort }: Params) {
  const handleSort = (event: React.MouseEvent<HTMLTableCellElement>) => {
    const dataKey = event.currentTarget.getAttribute("data-key");
    const sortable = Boolean(event.currentTarget.getAttribute("data-sortable"));
    if (!onSort || !dataKey || !sortable) return;

    if (dataKey === sortBy) {
      onSort(dataKey, sortOrder === "asc" ? "desc" : "asc");
    } else {
      onSort(dataKey, "asc");
    }
  };

  return {
    handleSort,
  };
}

export default useDataTable;
