import { Fragment } from "react";
import classNames from "classnames";

import { DataTableSchemaItem } from "types";

import styles from "../DataTable.module.scss";

interface Props {
  schema: DataTableSchemaItem[];
  sortBy?: string;
  sortOrder: "asc" | "desc";

  handleSort: React.MouseEventHandler<HTMLDivElement>;
}

function Header({
  schema,
  sortBy,
  sortOrder,

  handleSort,
}: Props) {
  return (
    <Fragment>
      {schema.map((s) => (
        <div
          className={styles.head}
          key={`header-${s.dataKey}`}
          data-key={s.dataKey}
          data-sortable={s.sortable}
          onClick={handleSort}
        >
          {s.sortable ? (
            <div className={styles.header_cell}>
              <span>{s.header}</span>

              <div
                className={classNames(
                  styles.sort_button,
                  styles[`--${sortOrder}`],
                  {
                    [styles[`--active`]]: s.dataKey === sortBy,
                  }
                )}
              />
            </div>
          ) : (
            s.header
          )}
        </div>
      ))}
    </Fragment>
  );
}

export default Header;
