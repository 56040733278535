import DataTable from "components/DataTable";
import ScriptStatus from "components/ScriptStatus";

const DATA = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => ({
  id: index,
  number: `#${2145 + index}`,
  date: "Just now",
  status: <ScriptStatus status="completed" />,
}));

const SCHEMA = [
  { dataKey: "number", header: "Request number" },
  { dataKey: "date", header: "Date & Time" },
  { dataKey: "status", header: "Request Status" },
];

function Requests() {
  return <DataTable schema={SCHEMA} data={DATA} />;
}

export default Requests;
