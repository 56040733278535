import SearchInput from "components/SearchInput";
import ListTabs from "components/ListTabs";
import ListWrapper from "components/ListWrapper";
import Typography from "components/Typography";
import ListActionsWrapper from "components/ListActionsWrapper";
import DataTable from "components/DataTable";

import useSupport from "./useSupport";

const TABS = [
  { title: "Active", value: "active" },
  { title: "Archived", value: "archived" },
];

const SCHEMA = [
  { dataKey: "number", header: "Request number" },
  {
    dataKey: "new_leaf_order_number",
    header: "New Leaf Order Number",
  },
  { dataKey: "created_at", header: "Date & Time", sortable: true },
  { dataKey: "customer", header: "Customer", sortable: true },
  { dataKey: "phone", header: "Phone number" },
];

function Suport() {
  const { status, setStatus, ...dataTableProps } = useSupport();

  return (
    <>
      <Typography variant="h2">Support</Typography>
      <ListWrapper>
        <ListTabs tabs={TABS} activeTabValue={status} onChange={setStatus} />
        <ListActionsWrapper>
          <SearchInput placeholder="Search" />
        </ListActionsWrapper>
        <DataTable schema={SCHEMA} {...dataTableProps} />
      </ListWrapper>
    </>
  );
}

export default Suport;
