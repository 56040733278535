import Spinner from "components/Spinner";
import Card from "components/Card";
import CardContent from "components/CardContent";
import Note from "components/Note";
import NoteForm from "components/NoteForm";
import ImageView from "components/ImageView";

import CustomerDetails from "./components/CustomerDetails";
import DoctorDetails from "./components/DoctorDetails";
import Header from "./components/Header";
import Prescriptions from "./components/Prescriptions";
import Otcs from "./components/Otcs";

import useRequestDetails from "./hooks";

import styles from "./RequestDetails.module.scss";
import ConfirmModal from "components/ConfirmModal";
import Typography from "components/Typography";
import TextField from "components/TextField";

function RequestDetails() {
  const {
    id,
    showInsurance,
    data,
    newleafNumber,
    isConfirmOpen,
    markDisabled,
    setShowInsurance,
    onChangeScriptStatus,
    onSubmitNote,
    onChangeNewleafNumber,
    complete,
    closeConfirm,
    confirmComplete,
  } = useRequestDetails();

  if (!data) return <Spinner />;

  return (
    <div>
      <Header
        id={id}
        created_at={data.created_at}
        status={data.status}
        app={data.app}
        completeDisabled={markDisabled}
        complete={complete}
        previous_id={data.previous_id}
        next_id={data.next_id}
      />

      <div className={styles.content}>
        <div className={styles.main_content}>
          <CustomerDetails pharmacy={data.pharmacy} customer={data.customer} />
          <DoctorDetails
            doctor={data.doctor}
            insurance={data.insurance}
            showInsurance={() => setShowInsurance(true)}
          />
          <Card>
            <CardContent dark>
              <NoteForm onSubmit={onSubmitNote} />
              {data.notes.map((note) => (
                <Note key={note.id} {...note} />
              ))}
            </CardContent>
          </Card>
        </div>
        <div className={styles.drugs}>
          <Prescriptions
            drugs={data.prescriptions}
            onChangeStatus={onChangeScriptStatus}
          />
          <Otcs drugs={data.otcs} />
        </div>
      </div>

      <ImageView
        show={showInsurance}
        onClose={() => setShowInsurance(false)}
        images={data.insurance.images}
      />

      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={closeConfirm}
        onConfirm={confirmComplete}
      >
        <Typography className={styles.confirm_title} variant="h2">
          Newleaf order number
        </Typography>
        <Typography variant="h4">
          Set a Newleaf order number to sync it with this request
        </Typography>
        <TextField
          label="Nickname"
          value={newleafNumber}
          onChange={onChangeNewleafNumber}
        />
      </ConfirmModal>
    </div>
  );
}

export default RequestDetails;
