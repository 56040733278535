import api from "api";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { ActionMeta, SingleValue } from "react-select";

import { Route } from "../../useDeliveryPlanner";
import { getDrivers, getRequestData, getVehicles } from "../../helpers";
import { getFullName } from "helpers/user";
import { SelectOption } from "types";

function useActions() {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const routeId = useMemo(
    () => new URLSearchParams(search).get("id"),
    [search]
  );
  const route = queryClient.getQueryData<Route>(["route", routeId]);
  const { data: vehicles } = useQuery("vehicles", getVehicles);
  const { data: drivers } = useQuery("drivers", getDrivers);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [sendModalIsOpen, setSendModalIsOpen] = useState(false);

  const [nickname, setNickname] = useState("");

  const vehicleOptions = useMemo(
    () =>
      vehicles?.results.map((v) => ({
        value: v.id,
        label: v.nickname,
      })),
    [vehicles]
  );
  const driverOptions = useMemo(
    () =>
      drivers?.results.map((d) => ({
        value: d.id,
        label: getFullName(d),
      })),
    [drivers]
  );
  const [assignedVehicleId, setAssignedVehicleId] = useState(0);
  const [assignedDriverId, setAssignedDriverId] = useState(0);

  useEffect(
    function () {
      setNickname(route?.nickname || "");
      setAssignedVehicleId(route?.vehicle?.id || 0);
      setAssignedDriverId(route?.driver?.id || 0);
    },
    [route]
  );

  function onDelete() {
    setDeleteModalIsOpen(true);
  }

  function onSave() {
    setSaveModalIsOpen(true);
  }

  function onSend() {
    setSendModalIsOpen(true);
  }

  function closeModals() {
    setDeleteModalIsOpen(false);
    setSaveModalIsOpen(false);
    setSendModalIsOpen(false);
  }

  function confirmDelete() {
    api.delete(`/routes/${route?.id}/`);
    setDeleteModalIsOpen(false);
    push("/settings/routes");
  }

  async function confirmSave() {
    if (!route) return;
    if (route.id) {
      const { data } = await api.patch<Route>(`/routes/${route.id}/`, {
        nickname,
      });
      queryClient.setQueriesData(["route", routeId], data);
    } else {
      const { data } = await api.post(
        "/routes/",
        getRequestData({ ...route, nickname })
      );
      push({ search: `?id=${data.id}` });
    }
    setNickname("");
    setSaveModalIsOpen(false);
  }

  async function confirmSend() {
    if (!route) return;

    try {
      const { data } = await api.patch<Route>(`/routes/${route.id}/`, {
        vehicle: assignedVehicleId,
        driver: assignedDriverId,
      });
      queryClient.setQueriesData(["route", routeId], data);
      await api.post(`/routes/${route.id}/send/`);
      setSendModalIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  }

  function handleChangeNickname(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    setNickname(value);
  }

  function handleChangeSendForm(
    nextValue: SingleValue<SelectOption>,
    meta: ActionMeta<SelectOption>
  ) {
    if (meta.name === "vehicle" && nextValue?.value) {
      const vehicle = vehicles?.results.find(
        (v) => v.id === Number(nextValue.value)
      );

      setAssignedVehicleId(Number(nextValue.value));
      setAssignedDriverId(vehicle?.driver?.id || 0);
    }

    if (meta.name === "driver" && nextValue?.value) {
      setAssignedDriverId(Number(nextValue.value));
    }
  }

  return {
    route,
    isValid: Boolean(
      route && route.start_location && route.end_location && route.start_time
    ),
    isCreated: Boolean(route && route.id),

    deleteModalIsOpen,
    saveModalIsOpen,
    sendModalIsOpen,

    nickname,

    vehicleOptions,
    driverOptions,

    assignedVehicle: vehicleOptions?.find((v) => v.value === assignedVehicleId),
    assignedDriver: driverOptions?.find((d) => d.value === assignedDriverId),

    onDelete,
    onSave,
    onSend,

    confirmDelete,
    confirmSave,
    confirmSend,

    closeModals,

    handleChangeNickname,
    handleChangeSendForm,
  };
}

export default useActions;
