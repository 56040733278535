import DatePicker from "react-datepicker";

import { format, parse, parseISO } from "date-fns";
import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz";

import { DATETIME_FORMAT } from "constants/datetime";

import styles from "./DateTimePicker.module.scss";

interface Props {
  onChange: (newValue: string) => void;
  value: string;
}

// This input shows New York time regardless of the system time zone
function DateTimePicker({ value, onChange }: Props) {
  const localTime = parseISO(value);
  const newYorkTimeString = formatInTimeZone(
    localTime,
    "America/New_York",
    DATETIME_FORMAT
  );
  // pretend our current timezone is "America/New_York"
  const fakeLocalTime = parse(newYorkTimeString, DATETIME_FORMAT, new Date());

  function handleChange(date: Date) {
    const offset = Math.abs(
      getTimezoneOffset("America/New_York", date) / (1000 * 60 * 60)
    ).toString();
    const timezone = `-${offset.padStart(2, "0")}:00`;

    onChange(
      format(date, "yyyy-MM-dd") + "T" + format(date, "HH:mm:ss") + timezone
    );
  }

  return (
    <DatePicker
      className={styles.datetime_picker}
      selected={fakeLocalTime}
      onChange={handleChange}
      dateFormat={DATETIME_FORMAT}
      showTimeSelect
      calendarClassName={styles.calendar}
    />
  );
}

export default DateTimePicker;
