import { useMemo, useState } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { format, parseISO } from "date-fns";
import { capitalize, flatten } from "lodash";

import Typography from "components/Typography";
import StatusBadge from "components/StatusBadge";

import api from "api";
import useSort from "hooks/useSort";

import { ListResponse, Order, OrderStatus, StatusColor } from "types";
import { DATETIME_FORMAT } from "constants/datetime";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";

const ACTIVE_STATUSES: OrderStatus[] = ["ready_for_delivery", "in_transit"];
const ARCHIVE_STATUSES: OrderStatus[] = ["delivered", "canceled"];

const tabToStatuses: Record<string, string> = {
  active: ACTIVE_STATUSES.join(","),
  archived: ARCHIVE_STATUSES.join(","),
};

const statusToColor: Record<OrderStatus, StatusColor> = {
  ready_for_delivery: "iris",
  in_transit: "fuschia",
  delivered: "green",
  canceled: "critical",
  pickup: "iris_dark",
};

async function getOrders({
  queryKey,
  pageParam,
}: QueryFunctionContext<string[]>) {
  const [, tab, order] = queryKey;
  const { limit, offset } = getLimitOffset(pageParam);

  const { data } = await api.get<ListResponse<Order>>("/orders/", {
    params: { status: tabToStatuses[tab], order, limit, offset },
  });

  return data;
}

function useDeliveries() {
  const [activeTab, setActiveTab] = useState("all");
  const { order, sortBy, sortOrder, onSort } = useSort("id");

  const {
    data: orders,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["deliveries", activeTab, order], getOrders, {
    getNextPageParam,
  });

  const data = useMemo(() => {
    if (!orders) return [];

    const results = flatten(orders.pages.map((page) => page.results));

    return results.map((o) => ({
      ...o,
      new_leaf_order: <Typography>#{o.new_leaf_order}</Typography>,
      created_at: format(parseISO(o.created_at), DATETIME_FORMAT),
      status: (
        <StatusBadge color={statusToColor[o.status]}>
          {capitalize(o.status.replaceAll("_", " "))}
        </StatusBadge>
      ),
    }));
  }, [orders]);

  return {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,

    sortBy,
    sortOrder,

    activeTab,

    fetchNextPage,
    onSort,
    setActiveTab,
  };
}

export default useDeliveries;
