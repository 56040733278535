import { useMutation, useQueryClient } from "react-query";

import api from "api";
import { RequestDetails } from "types";
import { useParams } from "react-router-dom";
import useCachedParams from "./useCachedParams";

function useCompleteRequest() {
  const { id } = useParams<{ id: string }>();

  const queryClient = useQueryClient();
  const { order, status } = useCachedParams();

  const completeRequest = useMutation(
    "complete_request",
    async (newleaf_number: string) => {
      await api.post(`/requests/${id}/complete/`, { newleaf_number });
      return;
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(["request_details", id, order, status]);

        const previousRequest = queryClient.getQueryData<RequestDetails>([
          "request_details",
          id,
          order,
          status,
        ]);

        if (!previousRequest) throw Error("Request is `undefined`");

        const nextRequest: RequestDetails = {
          ...previousRequest,
          status: "completed",
        };

        queryClient.setQueryData(
          ["request_details", id, order, status],
          nextRequest
        );

        return { previousRequest };
      },
      onSettled: () => {
        queryClient.invalidateQueries(["request_details", id, order, status]);
      },
    }
  );

  return completeRequest;
}

export default useCompleteRequest;
