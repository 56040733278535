import { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useQuery, QueryFunctionContext } from "react-query";

import Typography from "components/Typography";
import PageHeader from "components/PageHeader";
import Button from "components/Button";
import FormActionGroup from "components/FormActionGroup";
import FormGroup from "components/FormGroup";
import TextField from "components/TextField";
import SelectField from "components/SelectField";

import api from "api";

import { Driver, ListResponse, Vehicle } from "types";
import { getFullName } from "helpers/user";

interface Option {
  label: string;
  value: number;
}

interface FormValue {
  nickname: string;
  model: string;
  email: string;
  license_plate_number: string;
  driver?: Option;
}

async function getDrivers() {
  const { data } = await api.get<ListResponse<Driver>>("/drivers/");

  return data;
}

async function getVehicleById({ queryKey }: QueryFunctionContext) {
  const [, vehicleId] = queryKey;

  if (!vehicleId) return undefined;

  const { data } = await api.get<Vehicle>(`/vehicles/${vehicleId}`);

  return data;
}

function VehicleForm() {
  const { vehicleId } = useParams<{ vehicleId?: string }>();
  const { push } = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValue>();

  const { data: drivers } = useQuery("drivers", getDrivers);
  const { data: vehicle } = useQuery(["vehicle", vehicleId], getVehicleById);

  const options = useMemo(
    function () {
      if (!drivers) return [];

      return drivers.results.map((d) => ({
        value: d.id,
        label: getFullName(d),
      }));
    },
    [drivers]
  );

  useEffect(
    function () {
      if (!vehicle) return;

      reset({
        ...vehicle,
        driver: vehicle.driver
          ? {
              value: vehicle.driver.id,
              label: getFullName(vehicle.driver),
            }
          : undefined,
      });
    },
    [vehicle, reset]
  );

  async function submit(formValue: FormValue) {
    setSubmitting(true);

    const data = {
      ...formValue,
      driver: formValue.driver?.value,
    };

    if (vehicleId) {
      await api.put(`/vehicles/${vehicleId}/`, data);
    } else {
      await api.post("/vehicles/", data);
    }

    setSubmitting(false);
    push("/settings/vehicles");
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <PageHeader>
        <Typography variant="h2">
          {vehicleId ? "Edit Vehicle" : "New Vehicle"}
        </Typography>
        <FormActionGroup>
          <Button component="a" to="/settings/vehicles" color="gray">
            Cancel
          </Button>
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      <FormGroup columns={2}>
        <TextField
          label="Nickname"
          placeholder="Nickname"
          {...register("nickname", { required: "Required" })}
          error={errors.nickname?.message}
        />
        <TextField
          label="Vehicle Model"
          placeholder="Vehicle Model"
          {...register("model", { required: "Required" })}
          error={errors.model?.message}
        />
      </FormGroup>

      <FormGroup columns={1}>
        <TextField
          label="License plate number"
          placeholder="License plate number"
          {...register("license_plate_number", {
            required: "Required",
          })}
          error={errors.license_plate_number?.message}
        />
      </FormGroup>

      <FormGroup columns={1}>
        <Controller
          name="driver"
          control={control}
          render={({ field, fieldState }) => (
            <SelectField
              label="Assigned Driver"
              placeholder="No assigned driver"
              options={options}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </FormGroup>
    </form>
  );
}

export default VehicleForm;
