import React, { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./Button.module.scss";
import Spinner from "components/Spinner";

export type ButtonColor =
  | "green"
  | "primary"
  | "gray"
  | "blue"
  | "outlined"
  | "critical";

interface Props
  extends React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  component: "a" | "button";

  size: "lg" | "md" | "sm";
  color: ButtonColor;

  disabled?: boolean;
  loading?: boolean;

  to?: string;
}

function Button({
  component,
  className,
  children,
  size,
  color,
  loading,
  to,
  onClick,
  ...restProps
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (!onClick) return;

    setIsLoading(true);
    try {
      await onClick(event);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  if (component === "a" && to) {
    return (
      <Link
        to={to}
        className={cn(
          styles.root,
          styles[`--${size}`],
          styles[`--${color}`],
          { [styles[`--loading`]]: loading },
          className
        )}
        {...restProps}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        className={cn(
          styles.root,
          styles[`--${size}`],
          styles[`--${color}`],
          { [styles[`--loading`]]: loading || isLoading },
          className
        )}
        onClick={handleClick}
        {...restProps}
      >
        {children}
        {(loading || isLoading) && (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
      </button>
    );
  }
}

Button.defaultProps = {
  component: "button",
  size: "md",
  color: "primary",
  type: "button",
};

export default Button;
