import React from "react";

import { MenuItemName } from "types";

import IconBarcode from "./IconBarcode";
import IconChat from "./IconChat";
import IconCube from "./IconCube";
import IconGear from "./IconGear";
import IconHome from "./IconHome";
import IconPackage from "./IconPackage";
import IconRx from "./IconRx";
import IconTruck from "./IconTruck";
import IconUsers from "./IconUsers";

interface Props {
  className?: string;
  name: MenuItemName;
  color?: "primary" | "secondary";
}

const components: Record<MenuItemName, React.ReactElement> = {
  barcode: <IconBarcode />,
  chat: <IconChat />,
  cube: <IconCube />,
  gear: <IconGear />,
  home: <IconHome />,
  package: <IconPackage />,
  rx: <IconRx />,
  truck: <IconTruck />,
  users: <IconUsers />,
};

function MenuIcon({ className, name }: Props) {
  const Icon = components[name];
  return <div className={className}>{Icon}</div>;
}

export default MenuIcon;
