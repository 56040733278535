import React, { useCallback, useState } from "react";
import { AxiosResponse } from "axios";
import { useDropzone } from "react-dropzone";
import cn from "classnames";

import Spinner from "components/Spinner";

import api from "api";
import { Upload } from "types";
import { getFilename } from "helpers/string";

import close from "./icons/close.svg";
import thumb from "./icons/thumb.svg";

import styles from "./FileUpload.module.scss";

interface Props {
  label: string;
  multiple: boolean;
  files: Upload[];
  onChange?: (files: Upload[]) => void;
}

function FileUpload({ label, files, onChange }: Props) {
  const [placeholders, setPlaceholders] = useState<Upload[]>([]);
  const handleChange = useCallback(
    async function (uploads: File[]) {
      if (!uploads || !onChange) return;

      let requests: Promise<AxiosResponse<Upload>>[] = [];

      for (let i = 0; i < uploads.length; i++) {
        const formData = new FormData();
        formData.append("file", uploads[i]);

        requests = [...requests, api.post<Upload>("/images/", formData)];
      }

      setPlaceholders((prevState) => [
        ...prevState,
        ...uploads.map((file) => ({ id: -file.lastModified, file: file.name })),
      ]);

      try {
        const uploadedFiles = await Promise.all(requests);

        onChange([...files, ...uploadedFiles.map((u) => u.data)]);
      } catch {
        console.log("File uploading is failed");
      } finally {
        setPlaceholders([]);
      }
    },
    [files, onChange]
  );

  const { getInputProps, getRootProps } = useDropzone({
    onDropAccepted: handleChange,
  });

  function handlePropagation(event: React.MouseEvent) {
    event.stopPropagation();
  }

  async function handleRemove(event: React.MouseEvent<HTMLDivElement>) {
    const id = Number(event.currentTarget.getAttribute("data-id"));

    if (files && id) {
      onChange && onChange(files.filter((f) => f.id !== id));
      await api.delete(`/images/${id}/`);
    }
  }

  return (
    <div className={styles.root} {...getRootProps()}>
      <input {...getInputProps()} />
      <span className={styles.label}>{label}</span>
      <span className={styles.label}>Click or drag & drop to upload</span>
      <div className={styles.uploads}>
        {[...files, ...placeholders].map((file) => (
          <div
            key={file.id}
            className={cn(styles.thumb, {
              [styles["--uploading"]]: file.id < 0,
            })}
            onClick={handlePropagation}
          >
            <div
              className={styles.close}
              data-id={file.id}
              onClick={handleRemove}
            >
              <div className={styles.close__x} />
              <img alt="close icon" src={close} />
            </div>
            <div className={styles.image_container}>
              <img alt="thumb" src={thumb} />
            </div>
            <span className={styles.filename}>{getFilename(file.file)}</span>
            {file.id < 0 && <Spinner className={styles.spinner} />}
          </div>
        ))}
      </div>
    </div>
  );
}

FileUpload.defaultProps = {
  label: "Upload",
  files: [],
  multiple: true,
};

export default FileUpload;
