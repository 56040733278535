import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";

import Users from "./Users";
import Employees from "./Employees";
import Vehicles from "./Vehicles";
import Routes from "./Routes";

import styles from "./Settings.module.scss";

function Settings() {
  const { path } = useRouteMatch();

  return (
    <div className={styles.root}>
      <Switch>
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/employees`} component={Employees} />
        <Route path={`${path}/vehicles`} component={Vehicles} />
        <Route path={`${path}/routes`} component={Routes} />
        <Redirect to={`${path}/users`} />
      </Switch>
    </div>
  );
}

export default Settings;
