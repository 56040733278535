import { Link } from "react-router-dom";

import Typography from "components/Typography";
import Modal from "components/Modal";
import Button from "components/Button";
import Avatar from "components/Avatar";

import { getFullName } from "helpers/user";
import useAuth from "hooks/useAuth";

import sidebarStyles from "../Sidebar.module.scss";
import styles from "./UserModal.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function UserModal({ isOpen, onClose }: Props) {
  const { currentUser, logout } = useAuth();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2">Profile</Typography>

      <div className={styles.root}>
        <Avatar
          className={sidebarStyles.user__avatar}
          file={currentUser?.avatar}
        />
        <div className={styles.details}>
          <Typography className={styles.name} component="p" variant="h3">
            {getFullName(currentUser)}
          </Typography>
          <div className={styles.label}>
            <Typography variant="label">Account details</Typography>
            <Link
              to={`/settings/employees/${currentUser?.id}`}
              className={styles.edit}
            >
              Edit
            </Link>
          </div>
          <Typography component="p" variant="h3">
            Email: {currentUser?.email}
          </Typography>
          <br />
          <Typography component="p" variant="h3">
            Phone: {currentUser?.phone}
          </Typography>
        </div>
      </div>
      <Button size="sm" onClick={logout}>
        Logout
      </Button>
    </Modal>
  );
}

export default UserModal;
