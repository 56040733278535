import { useMemo, useState } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { format, parseISO } from "date-fns";
import { capitalize, flatten } from "lodash";

import ViewDetails from "components/ViewDetails";
import StatusBadge from "components/StatusBadge";

import { ListResponse, Route, RouteStatus, StatusColor } from "types";
import api from "api";
import { DATETIME_FORMAT } from "constants/datetime";
import useSort from "hooks/useSort";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";

const ACTIVE_STATUSES: RouteStatus[] = [
  "new",
  "processing",
  "processed",
  "awaiting",
  "active",
];

const COMPLETED_STATUSES: RouteStatus[] = ["completed"];

const tabToStatuses: Record<string, string> = {
  active: ACTIVE_STATUSES.join(","),
  completed: COMPLETED_STATUSES.join(","),
};

const statusToColor: Record<RouteStatus, StatusColor> = {
  new: "default",
  processing: "iris",
  processed: "green",
  awaiting: "iris",
  active: "green",
  completed: "gray",
};

async function getRoutes({
  queryKey,
  pageParam,
}: QueryFunctionContext<string[]>) {
  const [, order, tab] = queryKey;
  const { limit, offset } = getLimitOffset(pageParam);
  const { data } = await api.get<ListResponse<Route>>("/routes/", {
    params: { order, status: tabToStatuses[tab], limit, offset },
  });

  return data;
}

function useRoutes() {
  const [activeTab, setActiveTab] = useState("all");
  const { sortBy, sortOrder, order, onSort } = useSort("id");
  const {
    data: routes,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["routes", order, activeTab], getRoutes, {
    getNextPageParam,
  });

  const data = useMemo(
    function () {
      if (!routes) return [];

      const results = flatten(routes.pages.map((page) => page.results));

      return results.map((r) => ({
        ...r,
        number: `#${r.id}`,
        status: (
          <StatusBadge color={statusToColor[r.status]}>
            {capitalize(r.status)}
          </StatusBadge>
        ),
        created_at: format(parseISO(r.start_time), DATETIME_FORMAT),
        view: <ViewDetails to={`/deliveries/delivery_planner?id=${r.id}`} />,
      }));
    },
    [routes]
  );

  return {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,

    activeTab,

    sortBy,
    sortOrder,

    fetchNextPage,
    setActiveTab,
    onSort,
  };
}

export default useRoutes;
