import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";

import { InputProps } from "components/Input";

interface Option {
  id: string;
  address: string;
}

function usePlaceSearch(props: InputProps) {
  const [options, setOptions] = useState<Option[]>([]);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const autocompleteService = useRef(
    new google.maps.places.AutocompleteService()
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const search = useCallback(
    async function (input: string) {
      if (input.length <= 3) {
        setOptions([]);
        return;
      }

      setIsLoading(true);
      const response = await autocompleteService.current.getPlacePredictions({
        input,
        location: new google.maps.LatLng(40.7127753, -74.0059728),
        radius: 500_000,
        componentRestrictions: { country: "us" },
      });

      setOptions(
        response.predictions.map((p) => ({
          id: p.place_id,
          address: p.description.replace(", NY, USA", ""),
        }))
      );
      setIsLoading(false);
    },
    [setOptions]
  );

  const debouncedSearch = useMemo(() => debounce(search, 500), [search]);

  useEffect(() => {
    debouncedSearch(String(props.value));
  }, [debouncedSearch, props.value]);

  function onFocus() {
    setIsOptionsVisible(true);
  }

  function onBlur() {
    setIsOptionsVisible(false);
  }

  function onClickOption(event: React.MouseEvent<HTMLElement>) {
    const text = event.currentTarget.getAttribute("data-text") || "";

    let nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )?.set;
    nativeInputValueSetter?.call(inputRef.current, text);

    const inputEvent = new Event("input", { bubbles: true });

    inputRef.current?.dispatchEvent(inputEvent);

    setIsOptionsVisible(false);
    // setValue(text);
  }

  return {
    inputRef,

    value: props.value,
    options,
    isOptionsVisible,
    isLoading,

    onChange: props.onChange,
    onFocus,
    onBlur,
    onClickOption,
  };
}

export default usePlaceSearch;
