import React, { useState } from "react";
import { QueryFunctionContext, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import api from "api";
import { RequestDetails, SelectOption } from "types";
import { ScriptStatusOption } from "components/ScriptStatusSelect";

import { data as dummyData } from "../dummy";

import { ActionMeta, SingleValue } from "react-select";
import useCompleteRequest from "./useCompleteRequest";
import useUpdateSciptStatus from "./useUpdateScriptStatus";
import useCreateNote from "./useCreateNote";
import useCachedParams from "./useCachedParams";

async function getRequestById({
  queryKey,
}: QueryFunctionContext): Promise<RequestDetails> {
  const [, id, order, status] = queryKey;
  const { data } = await api.get<RequestDetails>(`/requests/${id}/`, {
    params: { order, status },
  });

  return { ...dummyData, ...data } as RequestDetails;
}

function useRequestDetails() {
  const { id } = useParams<{ id: string }>();

  const [showInsurance, setShowInsurance] = useState(false);

  const { order, status } = useCachedParams();

  const { data, isLoading } = useQuery(
    ["request_details", id, order, status],
    getRequestById
  );

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [newleafNumber, setNewleafNumber] = useState("");

  const completeRequest = useCompleteRequest();
  const updateScriptStatus = useUpdateSciptStatus();
  const createNote = useCreateNote();

  function onChangeScriptStatus(
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) {
    const drugId = Number(actionMeta.name?.split("-")[1]);
    if (newValue) {
      const status = newValue.value;
      updateScriptStatus.mutate({ drugId, status });
    }
  }

  function onSubmitNote(text: string) {
    return createNote.mutate(text);
  }

  function onChangeNewleafNumber(event: React.ChangeEvent<HTMLInputElement>) {
    setNewleafNumber(event.currentTarget.value);
  }

  function complete() {
    setIsConfirmOpen(true);
  }

  function closeConfirm() {
    setIsConfirmOpen(false);
  }

  async function confirmComplete() {
    await completeRequest.mutate(newleafNumber);
    setIsConfirmOpen(false);
  }

  return {
    id,
    showInsurance,
    data,
    isLoading,

    isConfirmOpen,
    newleafNumber,

    markDisabled: Boolean(
      data?.prescriptions.some(
        (drug) => drug.status !== "completed" && drug.status !== "cancel_script"
      )
    ),

    setShowInsurance,
    onSubmitNote,
    onChangeScriptStatus,
    onChangeNewleafNumber,
    complete,
    closeConfirm,
    confirmComplete,
  };
}

export default useRequestDetails;
