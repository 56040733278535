import DataTable from "components/DataTable";
import ListActionsWrapper from "components/ListActionsWrapper";
import ListWrapper from "components/ListWrapper";
import PageHeader from "components/PageHeader";
import SearchInput from "components/SearchInput";
import Typography from "components/Typography";

import styles from "../Settings.module.scss";
import useUsers from "./useUsers";

const SCHEMA = [
  {
    dataKey: "name",
    header: "Name",
    sortable: true,
  },
  {
    dataKey: "created_at",
    header: "Account creation date",
    sortable: true,
  },
  {
    dataKey: "view",
    header: "",
  },
];

function Users() {
  const dataTableProps = useUsers();

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Users</Typography>
      </PageHeader>
      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput size="sm" />
        </ListActionsWrapper>
        <DataTable schema={SCHEMA} {...dataTableProps} />
      </ListWrapper>
    </div>
  );
}

export default Users;
