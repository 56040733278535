import classNames from "classnames";

import { File } from "types";

import system_avatar from "./images/system_avatar.png";

import styles from "./Avatar.module.scss";

interface Props {
  className?: string;
  name?: string;
  file?: File;
  size: "sm" | "md" | "lg";
}

const AVATAR_PLACEHOLDER =
  "https://www.misemacau.org/wp-content/uploads/2015/11/avatar-placeholder-01.png";

function Avatar({ className, name, file, size }: Props) {
  return (
    <img
      className={classNames(styles.root, styles[`--${size}`], className)}
      alt={name || "avatar"}
      src={
        name === "System"
          ? system_avatar
          : file?.thumbnail || AVATAR_PLACEHOLDER
      }
    />
  );
}

Avatar.defaultProps = {
  size: "md",
};

export default Avatar;
