import { useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";

import Typography from "components/Typography";
import StatusBadge from "components/StatusBadge";
import ViewDetails from "components/ViewDetails";

import { ListResponse, Request } from "types";
import useSort from "hooks/useSort";
import api from "api";
import { mapStatusColor, mapStatusText } from "constants/mappings";
import { DATETIME_FORMAT } from "constants/datetime";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";
import { flatten } from "lodash";

async function getRequests({
  queryKey,
  pageParam,
}: QueryFunctionContext<string[]>) {
  const [, order, status] = queryKey;
  const { limit, offset } = getLimitOffset(pageParam);

  const { data } = await api.get<ListResponse<Request>>("/requests", {
    params: {
      order: order.includes("number") ? "" : order,
      status,
      limit,
      offset,
    },
  });

  return data;
}

function transformData(data: Request[]) {
  return data.map((item) => ({
    ...item,
    created_at: format(parseISO(item.created_at), DATETIME_FORMAT),
    number: <Typography variant="strong">#{item.id}</Typography>,
    status: (
      <StatusBadge color={mapStatusColor[item.status]}>
        {mapStatusText[item.status]}
      </StatusBadge>
    ),
    view: <ViewDetails to={`/requests/${item.id}`} />,
  }));
}

function useRequests() {
  const { sortBy, sortOrder, order, onSort } = useSort("number");
  const [status, setStatus] = useState("");
  const {
    data: requests,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["requests", order, status], getRequests, {
    getNextPageParam,
  });

  const tabs = useMemo(function () {
    return [
      { title: "All", value: "" },
      { title: "New Requests", value: "new" },
      { title: "Unresponsive", value: "unresponsive" },
      { title: "In Progress", value: "in_progress" },
      { title: "Completed", value: "completed" },
    ];
  }, []);

  const data = useMemo(() => {
    if (!requests) return [];

    const results = flatten(requests.pages.map((page) => page.results));

    return transformData(results);
  }, [requests]);

  return {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,

    sortBy,
    sortOrder,

    tabs,
    status,

    fetchNextPage,
    onSort,
    setStatus,
  };
}

export default useRequests;
