import { useMemo } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { format, parseISO } from "date-fns";
import { flatten } from "lodash";

import ViewDetails from "components/ViewDetails";

import api from "api";
import { getFullName } from "helpers/user";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";
import { ListResponse, Customer } from "types";
import { DATETIME_FORMAT } from "constants/datetime";
import useSort from "hooks/useSort";

async function getUsers({ queryKey, pageParam }: QueryFunctionContext) {
  const [, order] = queryKey;
  const { limit, offset } = getLimitOffset(pageParam);
  const { data } = await api.get<ListResponse<Customer>>("/customers/", {
    params: { order, limit, offset },
  });

  return data;
}

function useUsers() {
  const { sortBy, sortOrder, order, onSort } = useSort("name");
  const {
    data: users,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["users", order], getUsers, { getNextPageParam });

  const data = useMemo(
    function () {
      if (!users) return [];

      const results = flatten(users.pages.map((page) => page.results));

      return results.map((u) => ({
        ...u,
        name: getFullName(u),
        created_at: format(parseISO(u.created_at), DATETIME_FORMAT),
        view: <ViewDetails to={`/settings/users/${u.id}/requests`} />,
      }));
    },
    [users]
  );

  return {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,

    sortBy,
    sortOrder,

    fetchNextPage,
    onSort,
  };
}

export default useUsers;
