import { sum } from "lodash";

import pin from "./pin.png";

interface Result {
  markers: google.maps.Marker[];
  distance: number;
}

export async function calculateAndDisplayRoute(
  directionsService: google.maps.DirectionsService,
  directionsDisplay: google.maps.DirectionsRenderer,
  points: string[],
  start_location: string,
  end_location: string
): Promise<Result> {
  const endpoints: string[] = [
    start_location || "",
    ...points,
    end_location || "",
  ].filter((l) => Boolean(l));

  try {
    const response = await directionsService.route({
      origin: endpoints[0],
      waypoints: endpoints
        .slice(1, endpoints.length - 1)
        .map((p) => ({ location: p, stopover: true })),
      destination: endpoints[endpoints.length - 1],
      avoidTolls: true,
      avoidHighways: false,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
    });

    const { legs } = response.routes[0];

    directionsDisplay.setDirections(response);

    const start = new google.maps.Marker({
      position: legs[0].start_location,
      title: `Start`,
      label: "",
      icon: pin,
    });
    const end = new google.maps.Marker({
      position: legs[legs.length - 1].end_location,
      title: `Finish`,
      label: "",
      icon: pin,
    });

    const locations = legs.slice(0, legs.length - 1).map((l) => l.end_location);

    const markers = [
      start,
      ...locations.map(
        (p, index) =>
          new google.maps.Marker({
            position: p,
            title: `Point ${index + 1}`,
            label: {
              text: (index + 1).toString(),
              color: "#fff",
              fontSize: "18px",
              fontWeight: "700",
              fontFamily: '"SF Pro Display", sans-serif',
            },
            icon: pin,
          })
      ),
      end,
    ];

    return { markers, distance: sum(legs.map((l) => l.distance?.value || 0)) };
  } catch {
    return { markers: [], distance: 0 };
  }
}
