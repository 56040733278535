import DataTable from "components/DataTable";
import ListActionsWrapper from "components/ListActionsWrapper";
import ListTabs from "components/ListTabs";
import ListWrapper from "components/ListWrapper";
import SearchInput from "components/SearchInput";
import Typography from "components/Typography";

import useDeliveries from "./useDeliveries";

const SCHEMA = [
  { dataKey: "new_leaf_order", header: "New Leaf Order Number" },
  { dataKey: "created_at", header: "Date & Time" },
  { dataKey: "status", header: "Delivery Status" },
];

const tabs = [
  { title: "All", value: "all" },
  { title: "Active", value: "active" },
  { title: "Archived", value: "archived" },
];

function Deliveries() {
  const { activeTab, setActiveTab, ...dataTableProps } = useDeliveries();

  return (
    <>
      <Typography variant="h2">All deliveries</Typography>
      <ListWrapper>
        <ListTabs
          tabs={tabs}
          activeTabValue={activeTab}
          onChange={setActiveTab}
        />
        <ListActionsWrapper>
          <SearchInput placeholder="Search" />
        </ListActionsWrapper>
        <DataTable schema={SCHEMA} {...dataTableProps} />
      </ListWrapper>
    </>
  );
}

export default Deliveries;
