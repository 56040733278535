import Input, { InputProps } from "components/Input";
import Typography from "components/Typography";

import styles from "./PlaceSearch.module.scss";
import usePlaceSearch from "./usePlaceSearch";

function PlaceSearch(props: InputProps) {
  const {
    inputRef,

    value,
    options,
    isOptionsVisible,

    onChange,
    onFocus,
    onBlur,
    onClickOption,
  } = usePlaceSearch(props);

  return (
    <div className={styles.root}>
      <Input
        ref={inputRef}
        {...props}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {options.length > 0 && isOptionsVisible && (
        <ul className={styles.options_menu}>
          {options.map((o) => (
            <li
              className={styles.option}
              key={o.id}
              onMouseDown={onClickOption}
              data-text={o.address}
            >
              <Typography variant="p">{o.address}</Typography>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default PlaceSearch;
