import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

import Typography from "components/Typography";
import Back from "components/Back";
import Button from "components/Button";
import IconButton from "components/IconButton";
import StatusBadge from "components/StatusBadge";

import { Status } from "types";
import { mapStatusColor, mapStatusText } from "constants/mappings";

import styles from "../RequestDetails.module.scss";

interface Props {
  id: string;
  status: Status;
  created_at: string;
  app: string;
  completeDisabled: boolean;
  previous_id?: number;
  next_id?: number;
  complete: () => void;
}

function Header({
  id,
  status,
  created_at,
  app,
  completeDisabled,
  previous_id,
  next_id,
  complete,
}: Props) {
  const datetime = parseISO(created_at);
  const { push } = useHistory();
  return (
    <div className={styles.header}>
      <div>
        <div className={styles.title_wrapper}>
          <Back />
          <Typography className={styles.title} variant="h2">
            Request #{id}
          </Typography>
          <StatusBadge color={mapStatusColor[status]}>
            {mapStatusText[status]}
          </StatusBadge>
        </div>

        <Typography className={styles.subtitle} variant="h5">
          {format(datetime, "MMMM d, yyyy")} at {format(datetime, "h:mm aaa")}{" "}
          from {app} app
        </Typography>
      </div>
      <div className={styles.buttons}>
        {status !== "completed" && (
          <Button
            className={styles.complete_btn}
            color="green"
            disabled={completeDisabled}
            onClick={complete}
          >
            Mark transfers as complete
          </Button>
        )}
        <IconButton
          icon="arrow_left"
          disabled={!previous_id}
          onClick={() => push(`/requests/${previous_id}`)}
        />
        <IconButton
          icon="arrow_right"
          disabled={!next_id}
          onClick={() => push(`/requests/${next_id}`)}
        />
      </div>
    </div>
  );
}

export default Header;
