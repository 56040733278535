import { useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { SingleValue } from "react-select";
import { flatten } from "lodash";

import ViewDetails from "components/ViewDetails";

import { ListResponse, SelectOption, User } from "types";
import api from "api";
import { getFullName } from "helpers/user";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";
import roles from "constants/roles";

import localStyles from "./Employees.module.scss";
import useSort from "hooks/useSort";

const filterOptions: SelectOption[] = [
  { value: "", label: "All employees" },
  ...roles,
];

async function getEmployees({ queryKey, pageParam }: QueryFunctionContext) {
  console.log("queryKey", pageParam);
  const { limit, offset } = getLimitOffset(pageParam);

  const [, role, order] = queryKey;
  const { data } = await api.get<ListResponse<User>>("/users/", {
    params: { role, order, limit, offset },
  });

  return data;
}

function useEmployees() {
  const { url } = useRouteMatch();
  const [filterValue, setFilterValue] = useState<SelectOption>(
    filterOptions[0]
  );
  const { sortBy, sortOrder, order, onSort } = useSort("name");

  const {
    data: employees,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["employees", filterValue.value, order], getEmployees, {
    getNextPageParam,
  });

  const data = useMemo(
    function () {
      if (!employees) return [];

      const results = flatten(employees.pages.map((page) => page.results));

      return results.map((employee) => ({
        ...employee,
        name: getFullName(employee),
        role: <span className={localStyles.role}>{employee.role}</span>,
        view: <ViewDetails to={`${url}/${employee.id}`} />,
      }));
    },
    [employees, url]
  );

  function onChangeFilter(newValue: SingleValue<SelectOption>) {
    newValue && setFilterValue(newValue);
  }

  return {
    data,

    isLoading,
    hasNextPage,
    isFetchingNextPage,

    filterOptions,
    filterValue,

    sortBy,
    sortOrder,

    fetchNextPage,
    onChangeFilter,
    onSort,
  };
}

export default useEmployees;
