import { Fragment } from "react";
import classNames from "classnames";
import pluralize from "pluralize";
import { Wrapper } from "@googlemaps/react-wrapper";

import Button from "components/Button";
import DateTimePicker from "components/DateTimePicker";

import Filter from "./components/Filter";
import Actions from "./components/Actions";
import AssignedHeader from "./components/AssignedHeader";
import Sort from "./components/Sort";
import Ticket from "./components/Ticket";
import Map from "./components/Map";

import useDeliveryPlanner from "./useDeliveryPlanner";

import stat_path from "./icons/stat_path.svg";
import stat_stops from "./icons/stat_stops.svg";
import stat_time from "./icons/stat_time.svg";

import timer from "./icons/timer.svg";

import styles from "./DeliveryPlanner.module.scss";

import "react-datepicker/dist/react-datepicker.css";

function DeliveryPlanner() {
  const {
    route,
    currentVehicle,
    vehicles,

    unassignedOrders,

    selectedUnassignedOrders,
    selectedAssignedOrders,

    stats,

    isValid,

    onChangeUnassignedCheckbox,
    onChangeAssignedCheckbox,
    onChangeRouteParams,
    onChangeStartTime,

    assignSelected,
    unassignSelected,

    calculate,
  } = useDeliveryPlanner();

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
      libraries={["places"]}
    >
      <div className={styles.root}>
        <div className={styles.panel}>
          <div className={classNames(styles.panel__header)}>
            <span className={styles.panel__inbox_title}>
              Inbox ({unassignedOrders.length})
            </span>
          </div>
          <div className={styles.panel__filters}>
            <Filter />
            <Sort />
          </div>
          <div className={styles.panel__content}>
            {unassignedOrders.map((ticket) => (
              <Ticket
                key={ticket.id}
                {...ticket}
                selected={selectedUnassignedOrders.includes(ticket.id)}
                onChangeCheckbox={onChangeUnassignedCheckbox}
              />
            ))}
          </div>
          <div className={styles.panel__bottom}>
            <Button
              size="lg"
              color="blue"
              disabled={!selectedUnassignedOrders.length}
              onClick={assignSelected}
            >
              Assign to vehicle
            </Button>
          </div>
        </div>
        <div className={styles.panel}>
          <AssignedHeader
            currentVehicle={currentVehicle}
            vehicles={vehicles?.results}
            startLocation={route.start_location}
            endLocation={route.end_location}
            onChange={onChangeRouteParams}
          />
          <div className={styles.panel__filters}>
            <div className={styles.panel__start}>
              <span>Starts at</span>
              <div className={styles.datetime_picker_wrapper}>
                <img alt="timer icon" src={timer} />
                <DateTimePicker
                  value={route.start_time}
                  onChange={onChangeStartTime}
                />
              </div>
            </div>
            <Button
              className={styles.panel__unassign}
              disabled={!selectedAssignedOrders.length}
              size="sm"
              onClick={unassignSelected}
            >
              Unassign selected
            </Button>
          </div>
          <div
            className={classNames(styles.panel__content, styles["--assigned"])}
          >
            {route.orders.map((ticket, index) => (
              <Ticket
                key={ticket.id}
                index={route.status === "processed" ? index + 1 : undefined}
                selected={selectedAssignedOrders.includes(ticket.id)}
                onChangeCheckbox={onChangeAssignedCheckbox}
                {...ticket}
              />
            ))}
          </div>
          <div className={styles.panel__assigned_buttom}>
            <div className={styles.panel__assigned_summary}>
              <span className={styles.panel__deliveries_counter}>
                {route.orders.length}{" "}
                {pluralize("Delivery", route.orders.length)}
              </span>
              <div className={styles.panel__deliveries_end_time}>
                <img alt="timer" src={stat_time} />
                <span>Ends at</span> {stats.end_time}
              </div>
            </div>
            {route.status === "processed" ? (
              <div className={styles.panel__assigned_stats}>
                <Fragment>
                  <div className={styles.panel__assigned_stat}>
                    <img alt="stat icon" src={stat_time} />
                    <span>{stats.total_time}</span>
                    <span>Total time</span>
                  </div>
                  <div className={styles.panel__assigned_stat}>
                    <img alt="stat icon" src={stat_path} />
                    <span>{stats.distance}</span>
                    <span>Distance</span>
                  </div>
                  <div className={styles.panel__assigned_stat}>
                    <img alt="stat icon" src={stat_stops} />
                    <span>{stats.stops}</span>
                    <span>Stops</span>
                  </div>
                </Fragment>
              </div>
            ) : (
              <div className={styles.panel__calc}>
                <Button
                  color="blue"
                  onClick={calculate}
                  disabled={!route.orders.length || !isValid}
                >
                  Calculate
                </Button>
              </div>
            )}

            <Actions />
          </div>
        </div>
        <div className={styles.panel}>
          <div className={classNames(styles.panel__header)}>
            <span className={styles.panel__inbox_title}>Preview</span>
          </div>
          <Map
            orders={route.orders}
            start_location={route.start_location}
            end_location={route.end_location}
            display={route.status === "processed"}
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default DeliveryPlanner;
