import classNames from "classnames";

import Checkbox from "components/Checkbox";

import styles from "./CheckboxField.module.scss";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

function CheckboxField({ label, className, ...restProps }: Props) {
  return (
    <div className={classNames(styles.root, className)}>
      <Checkbox {...restProps} />
      <label className={styles.label}>{label}</label>
    </div>
  );
}

export default CheckboxField;
