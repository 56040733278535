import { Controller } from "react-hook-form";

import Typography from "components/Typography";
import PageHeader from "components/PageHeader";
import Button from "components/Button";
import FormActionGroup from "components/FormActionGroup";
import FormGroup from "components/FormGroup";
import TextField from "components/TextField";
import SelectField from "components/SelectField";
import ListWrapper from "components/ListWrapper";
import CardContent from "components/CardContent";

import states from "constants/states";
import { validateEmail } from "utils/validators";

import useUserForm from "./useUserForm";

// import styles from "./UserForm.module.scss";

function UserForm() {
  const { userId, submitting, errors, control, register, handleSubmit } =
    useUserForm();

  return (
    <form onSubmit={handleSubmit}>
      <PageHeader>
        <Typography variant="h2">
          {userId ? "Edit User" : "New User"}
        </Typography>
        <FormActionGroup>
          <Button to="/settings/drivers" component="a" color="gray">
            Cancel
          </Button>
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register("first_name", { required: "Required" })}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Surname"
              {...register("last_name", { required: "Required" })}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register("email", {
                required: "Required",
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="Phone number"
              {...register("phone", {
                required: "Required",
              })}
              error={errors.phone?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Date of birth"
              placeholder="mm/ddd/yyyy"
              {...register("birthday", {
                required: "Required",
              })}
              error={errors.phone?.message}
            />
          </FormGroup>

          <FormGroup columns={2}>
            <TextField
              label="Address line 1"
              placeholder="Street"
              {...register("address_line_1", {
                required: "Required",
              })}
              error={errors.address_line_1?.message}
            />
            <TextField
              label="Address line 2"
              placeholder="Apartment"
              {...register("address_line_2")}
              error={errors.address_line_2?.message}
            />
          </FormGroup>

          <FormGroup columns={3}>
            <TextField
              label="City"
              placeholder="City name"
              {...register("city", {
                required: "Required",
              })}
              error={errors.city?.message}
            />
            <TextField
              label="Zipcode"
              placeholder="Zipcode"
              {...register("zipcode", {
                required: "Required",
              })}
              error={errors.zipcode?.message}
            />
            <Controller
              name="state"
              control={control}
              rules={{ required: "Required" }}
              render={({ field, fieldState }) => (
                <SelectField
                  label="State"
                  placeholder="Select a state"
                  options={states}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </FormGroup>
        </CardContent>
      </ListWrapper>
    </form>
  );
}

export default UserForm;
