import React, { Fragment, useEffect, useMemo, useState } from "react";
import { SingleValue } from "react-select";
import classNames from "classnames";

import Typography from "components/Typography";
import Select from "components/Select";
import Button from "components/Button";

import PlaceSearch from "../PlaceSearch";

import { SelectOption, Vehicle } from "types";

import truck from "../../icons/truck.svg";
import vehicle from "../../icons/vehicle.svg";
import location from "../../icons/location.svg";

import styles from "../../DeliveryPlanner.module.scss";
import localStyles from "./AssignedHeader.module.scss";

interface Props {
  currentVehicle?: Vehicle;
  vehicles?: Vehicle[];
  startLocation?: string;
  endLocation?: string;
  onChange: (
    startLocation: string,
    endLocation: string,
    currentVehicle?: Vehicle
  ) => void;
}

function AssignedHeader({
  currentVehicle,
  vehicles,
  startLocation,
  endLocation,
  onChange,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<number>();
  const [endpoints, setEndpoints] = useState({
    startLocation: "",
    endLocation: "",
  });

  const options = useMemo(
    function () {
      return vehicles?.map((v) => ({
        label: (
          <div className={localStyles.option}>
            <img alt="truck" src={vehicle} />
            {v.nickname}
          </div>
        ),
        value: v.id,
      }));
    },
    [vehicles]
  );

  function onChangeVehicle(newValue: SingleValue<SelectOption>) {
    setSelectedVehicleId(Number(newValue?.value));
  }

  function onChangeEndpoints(event: React.ChangeEvent<HTMLInputElement>) {
    setEndpoints({
      ...endpoints,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  }

  useEffect(
    function () {
      setSelectedVehicleId(currentVehicle?.id);
      setEndpoints({
        startLocation: startLocation || "",
        endLocation: endLocation || "",
      });
    },
    [currentVehicle, startLocation, endLocation]
  );

  return (
    <Fragment>
      <div className={classNames(styles.panel__header, styles["--dark"])}>
        <div className={styles.panel__vehicle_title}>
          <p className={styles.panel__vehicle}>
            <img alt="truck icon" src={truck} />
            {currentVehicle?.nickname || "not assigned"}
          </p>
          <p className={styles.panel__endpoints}>
            <Typography component="span" variant="a">
              {startLocation || "no start location"}
            </Typography>{" "}
            to{" "}
            <Typography component="span" variant="a">
              {endLocation || "no end location"}
            </Typography>
          </p>
        </div>
        <span
          className={styles.panel__change_vehicle}
          onClick={() => setExpanded((prev) => !prev)}
        >
          Change
        </span>
      </div>
      <div
        className={classNames(localStyles.form, {
          [localStyles["--expanded"]]: expanded,
        })}
      >
        <div className={localStyles.form__content}>
          <div className={localStyles.label}>Vehicle:</div>
          <Select
            size="sm"
            options={options}
            value={options?.find((o) => o.value === selectedVehicleId)}
            onChange={onChangeVehicle}
            variant="round"
          />
          <div className={localStyles.label}>Start loc:</div>
          <PlaceSearch
            name="startLocation"
            size="sm"
            icon={<img alt="location" src={location} />}
            value={endpoints.startLocation}
            onChange={onChangeEndpoints}
          />
          <div className={localStyles.label}>End loc:</div>
          <PlaceSearch
            name="endLocation"
            size="sm"
            icon={<img alt="location" src={location} />}
            value={endpoints.endLocation}
            onChange={onChangeEndpoints}
          />
          <div className={localStyles.actions}>
            <Button
              color="outlined"
              size="sm"
              onClick={() => setExpanded(false)}
            >
              Cancel
            </Button>
            <Button
              color="green"
              size="sm"
              onClick={() => {
                onChange(
                  endpoints.startLocation,
                  endpoints.endLocation,
                  vehicles?.find((v) => v.id === selectedVehicleId)
                );
                setExpanded(false);
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AssignedHeader;
