import { useCallback } from "react";
import { components, SingleValueProps } from "react-select";
import { truncate } from "lodash";

import Select from "components/Select";

import { SelectOption } from "types";
import { useFilterStore } from "pages/DeliveryPlanner/stores";

import "./Filter.scss";

const options = [
  {
    label: "Location",
    options: [
      { value: "brooklyn", label: "Brooklyn" },
      { value: "manhattan", label: "Manhattan" },
      { value: "queens", label: "Queens" },
      { value: "staten_island", label: "Staten Island" },
      { value: "bronx", label: "Bronx" },
      { value: "jersey_city", label: "Jersey City" },
    ],
  },
  {
    label: "Time Preference",
    options: [
      { value: "morning", label: "Morning" },
      { value: "afternoon", label: "Afternoon" },
      { value: "evening", label: "Evening" },
    ],
  },
];

const allOptions = [...options[0].options, ...options[1].options];

function SingleValue(props: SingleValueProps<SelectOption, false>) {
  const filters = useFilterStore(
    useCallback((state) => [...state.location, ...state.time], [])
  );
  const values = allOptions
    .filter((o) => filters.includes(o.value))
    .map((o) => o.label);

  return (
    <components.SingleValue {...props}>
      {truncate(values.join(", "), { length: 18 })}
    </components.SingleValue>
  );
}

function Filter() {
  const { filters, setFilters } = useFilterStore(
    useCallback(
      (state) => ({
        filters: [...state.location, ...state.time],
        setFilters: state.setFilters,
      }),
      []
    )
  );
  const value = allOptions.filter((o) => filters.includes(o.value));
  return (
    <Select
      className="DeliveryPlanner__Filter"
      placeholder="Filter"
      isSearchable={false}
      options={options}
      value={value}
      closeMenuOnSelect={false}
      components={{ SingleValue }}
      onChange={(newValue) => {
        const index = filters.findIndex((f) => f === newValue?.value);

        if (index >= 0) {
          setFilters([...filters.slice(0, index), ...filters.slice(index + 1)]);
        } else {
          if (typeof newValue?.value === "string")
            setFilters([...filters, newValue?.value]);
        }
      }}
    />
  );
}

export default Filter;
