export const defaultOptions: google.maps.MapOptions = {
  center: {
    lat: 40.7128,
    lng: -74.006,
  },
  zoom: 11,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  keyboardShortcuts: false,
};
