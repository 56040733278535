import { QueryFunctionContext } from "react-query";
import { pick } from "lodash";

import { Driver, ListResponse, Order, Vehicle } from "types";

import api from "api";

import { Route } from "./useDeliveryPlanner";
import { defaultRoute } from "./constants";

export async function getDrivers() {
  const { data } = await api.get<ListResponse<Driver>>("/drivers/");
  return data;
}

export async function getVehicles() {
  const { data } = await api.get<ListResponse<Vehicle>>("/vehicles/");
  return data;
}

export async function getOrders({ queryKey }: QueryFunctionContext) {
  const [, location, time, sort] = queryKey;
  const { data } = await api.get<ListResponse<Order>>("/orders/", {
    params: { location, time, order: sort },
  });
  return data;
}

export async function getRouteById({ queryKey }: QueryFunctionContext) {
  const [, routeId] = queryKey;
  if (routeId) {
    const { data } = await api.get<Route>(`/routes/${routeId}/`);
    return data;
  }

  return defaultRoute;
}

export function getRequestData(route: Route) {
  return {
    ...pick(route, [
      "nickname",
      "start_time",
      "start_location",
      "end_location",
    ]),
    driver: route.driver?.id,
    vehicle: route.vehicle?.id,
    orders: route.orders.map((o) => o.id),
  };
}
