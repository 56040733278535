import { Fragment } from "react";
import classNames from "classnames";

import { DataItem, DataTableSchemaItem } from "types";

import styles from "../DataTable.module.scss";

interface Props {
  schema: DataTableSchemaItem[];
  data: DataItem[];
  onClick?: (item: DataItem) => any;
}

function Data({ schema, data, onClick }: Props) {
  return (
    <Fragment>
      {data.map((row) => (
        <Fragment key={`row-${row.id}`}>
          {schema.map((s, index) => (
            <div
              key={`cell-${row.id}-${index}`}
              onClick={() => onClick && !s.actionCell && onClick(row)}
              className={classNames(styles.cell, {
                [styles[`--${s.align}`]]: s.align,
              })}
            >
              {row[s.dataKey]}
            </div>
          ))}
          <div className={styles.border} />
        </Fragment>
      ))}
    </Fragment>
  );
}

export default Data;
