import { ListResponse } from "types";
import { LIMIT } from "constants/server";

export function getNextPageParam(
  lastPage: ListResponse<any>,
  pages: ListResponse<any>[]
) {
  const left = lastPage.count - pages.length * LIMIT;

  return left > 0 ? pages.length : undefined;
}

export function getLimitOffset(pageParam: any) {
  const page = Number(pageParam) || 0;
  return { limit: LIMIT, offset: page * LIMIT };
}
