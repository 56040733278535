import Button from "components/Button";
import DataTable from "components/DataTable";
import ListActionsWrapper from "components/ListActionsWrapper";
import ListWrapper from "components/ListWrapper";
import SearchInput from "components/SearchInput";
import PageHeader from "components/PageHeader";
import Typography from "components/Typography";

import useVehicles from "./useVehicles";

import styles from "../Settings.module.scss";
import { DataTableSchemaItem } from "types";

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: "nickname",
    header: "Vehicle nickname",
    sortable: true,
  },
  {
    dataKey: "license_plate_number",
    header: "Vehicle plate",
    sortable: true,
  },
  {
    dataKey: "driver",
    header: "Assigned driver",
    sortable: true,
  },
  {
    dataKey: "view",
    header: "",
    align: "right",
  },
];

function Vehicles() {
  const dataTableProps = useVehicles();

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Vehicles</Typography>
        <Button to="/settings/vehicles/new" component="a" color="green">
          New Vehicle
        </Button>
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput size="sm" />
        </ListActionsWrapper>
        <DataTable schema={SCHEMA} {...dataTableProps} />
      </ListWrapper>
    </div>
  );
}

export default Vehicles;
