import { useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";

import Typography from "components/Typography";

import { DataItem, ListResponse, SupportRequest } from "types";
import useSort from "hooks/useSort";
import api from "api";
import { DATETIME_FORMAT } from "constants/datetime";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";
import { flatten } from "lodash";

async function getSupportRequests({
  queryKey,
  pageParam,
}: QueryFunctionContext) {
  const [, order, status] = queryKey;
  const { limit, offset } = getLimitOffset(pageParam);

  const { data } = await api.get<ListResponse<SupportRequest>>("/support/", {
    params: { order, status, limit, offset },
  });

  return data;
}

function transformData(item: SupportRequest): DataItem {
  return {
    ...item,
    number: <Typography variant="strong">#{item.id}</Typography>,
    new_leaf_order_number: item.new_leaf_order_number && (
      <Typography variant="strong">#{item.new_leaf_order_number}</Typography>
    ),
    created_at: format(parseISO(item.created_at), DATETIME_FORMAT),
  };
}

function useSupport() {
  const [status, setStatus] = useState("active");
  const { sortBy, sortOrder, onSort, order } = useSort("created_at");

  const {
    data: supportRequests,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["support_requests", order, status],
    getSupportRequests,
    { getNextPageParam }
  );
  const data = useMemo(
    function () {
      if (!supportRequests) return [];

      const results = flatten(
        supportRequests.pages.map((page) => page.results)
      );

      return results.map(transformData);
    },
    [supportRequests]
  );
  return {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,

    status,

    sortBy,
    sortOrder,

    fetchNextPage,
    setStatus,
    onSort,
  };
}

export default useSupport;
