import { createContext } from "react";

const uiContext = createContext<{
  sidebarExtended: boolean;
  setSidebarExtended: (sidebarExtended: boolean) => void;

  fullsizeContent: boolean;
  setFullsizeContent: (fullsizeContent: boolean) => void;
}>({
  sidebarExtended: true,
  setSidebarExtended: () => {},

  fullsizeContent: false,
  setFullsizeContent: () => {},
});

export default uiContext;
