import { DataItem, DataTableSchemaItem } from "types";

import Header from "./components/Header";
import Data from "./components/Data";
import Loader from "./components/Loader";
import Empty from "./components/Empty";

import useDataTable from "./useDataTable";

import styles from "./DataTable.module.scss";
import Button from "components/Button";

interface Props {
  schema: DataTableSchemaItem[];
  data: DataItem[];
  sortBy?: string;
  sortOrder: "asc" | "desc";
  onSort?: (sortBy: string, sortOrder: "asc" | "desc") => any;
  loading?: boolean; // DEPRECATED
  isLoading?: boolean;
  onClick?: (item: DataItem) => any;

  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
}

function getTemplateColumns(schema: DataTableSchemaItem[]) {
  const cols = schema
    .map((s) => s.colWidth || "1fr")
    .filter((col) => Boolean(col))
    .join(" ");

  return cols;
}

function DataTable({
  schema,
  data,
  sortBy,
  sortOrder,

  onSort,
  onClick,

  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,

  ...restProps
}: Props) {
  const { handleSort } = useDataTable({
    sortBy,
    sortOrder,
    onSort,
  });
  const isLoading = restProps.isLoading || restProps.loading;

  return (
    <div className={styles.root}>
      <div
        className={styles.table}
        style={{ gridTemplateColumns: getTemplateColumns(schema) }}
      >
        <Header
          schema={schema}
          sortBy={sortBy}
          sortOrder={sortOrder}
          handleSort={handleSort}
        />

        {!isLoading && <Data schema={schema} data={data} onClick={onClick} />}
      </div>
      {isLoading && <Loader />}
      {!isLoading && data.length === 0 && <Empty />}
      {!isLoading && hasNextPage && (
        <div className={styles.load_more}>
          <Button
            color="outlined"
            size="sm"
            loading={isFetchingNextPage}
            onClick={fetchNextPage}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
}

DataTable.defaultProps = {
  selectable: false,
  sortOrder: "asc",
};

export default DataTable;
