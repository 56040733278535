import SearchInput from "components/SearchInput";
import ListTabs from "components/ListTabs";
import ListWrapper from "components/ListWrapper";
import Typography from "components/Typography";
import ListActionsWrapper from "components/ListActionsWrapper";
import DataTable from "components/DataTable";

import { DataTableSchemaItem } from "types";

import useRequests from "./useRequests";

const SCHEMA: DataTableSchemaItem[] = [
  { dataKey: "number", header: "Request number", sortable: false },
  { dataKey: "created_at", header: "Created at", sortable: true },
  { dataKey: "customer", header: "Customer", sortable: true },
  { dataKey: "status", header: "Request status", sortable: true },
  { dataKey: "view", header: "", align: "right" },
];

function Requests() {
  const { tabs, status, setStatus, ...dataTableProps } = useRequests();

  return (
    <>
      <Typography variant="h2">Requests</Typography>
      <ListWrapper>
        <ListTabs tabs={tabs} activeTabValue={status} onChange={setStatus} />
        <ListActionsWrapper>
          <SearchInput placeholder="Search" />
        </ListActionsWrapper>
        <DataTable schema={SCHEMA} {...dataTableProps} />
      </ListWrapper>
    </>
  );
}

export default Requests;
