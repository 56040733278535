import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { truncate } from "lodash";
import cn from "classnames";

import Avatar from "components/Avatar";

import MenuIcon from "./MenuIcon";
import UserModal from "./UserModal";

import useAuth from "hooks/useAuth";
import { getFullName } from "helpers/user";
import useUi from "hooks/useUi";
import { MenuItemName } from "types";

import logo from "./images/logo.svg";

import styles from "./Sidebar.module.scss";

interface SubmenuItem {
  title: string;
  path: string;
  exact?: boolean;
}

interface MenuItem {
  name: MenuItemName;
  title: string;
  path: string;
  exact?: boolean;
  subitems?: SubmenuItem[];
}

const MENU_ITEMS: MenuItem[] = [
  {
    name: "truck",
    title: "Dispatch",
    path: "/deliveries",
    subitems: [
      { title: "Delivery planner", path: "/deliveries/delivery_planner" },
      { title: "All deliveries", path: "/deliveries", exact: true },
      { title: "Instore pickups", path: "/deliveries/in-store_pickups" },
    ],
  },
  { name: "rx", title: "Requests", path: "/requests" },
  { name: "chat", title: "Support", path: "/support" },
  {
    name: "gear",
    title: "Settings",
    path: "/settings",
    subitems: [
      { title: "Users", path: "/settings/users" },
      { title: "Employees", path: "/settings/employees" },
      { title: "Vehicles", path: "/settings/vehicles" },
      { title: "Routes", path: "/settings/routes" },
    ],
  },
];

function Sidebar() {
  const { currentUser } = useAuth();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const { pathname } = useLocation();
  const { sidebarExtended } = useUi();

  useEffect(
    function () {
      setIsUserModalOpen(false);
    },
    [pathname]
  );

  return (
    <div
      className={cn(styles.root, { [styles["--narrow"]]: !sidebarExtended })}
    >
      <img className={styles.logo} alt="meds logo" src={logo} />
      <div className={styles.nav_padding} />
      <nav className={styles.nav}>
        <ul className={styles.menu}>
          {MENU_ITEMS.map((item) => (
            <li className={styles.menu_item} key={item.name}>
              <NavLink
                to={item.path}
                activeClassName={styles.active}
                exact={item.exact}
              >
                <MenuIcon className={styles.menu_item__icon} name={item.name} />
                <span className={styles.text}>{item.title}</span>
              </NavLink>

              {item.subitems && (
                <ul
                  className={cn(styles.submenu, {
                    [styles["--hidden"]]: !pathname.includes(item.path),
                  })}
                >
                  {item.subitems.map((subitem) => (
                    <li className={styles.submenu__item} key={subitem.path}>
                      <NavLink
                        to={subitem.path}
                        activeClassName={styles["--active"]}
                        exact={subitem.exact}
                      >
                        {subitem.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.user_wrapper}>
        <div className={styles.user} onClick={() => setIsUserModalOpen(true)}>
          <div className={styles.user__details}>
            <span className={styles.user__name}>
              {getFullName(currentUser)}
            </span>
            <span className={styles.user__email}>
              {truncate(currentUser?.email, { length: 20, omission: "..." })}
            </span>
          </div>
          <Avatar className={styles.user__avatar} file={currentUser?.avatar} />
        </div>
      </div>
      <UserModal
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
      />
    </div>
  );
}

export default Sidebar;
