import { SingleValue, ActionMeta } from "react-select";

import Typography from "components/Typography";
import Card from "components/Card";
import CardContent from "components/CardContent";
import ScriptStatusSelect, {
  ScriptStatusOption,
} from "components/ScriptStatusSelect";

import { Prescription, SelectOption } from "types";

import styles from "../RequestDetails.module.scss";

interface Props {
  drugs: Prescription[];
  onChangeStatus: (
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
}

function Prescriptions({ drugs, onChangeStatus }: Props) {
  return (
    <Card>
      <CardContent px="sm" pt="md" pb="sm">
        <Typography component="p" variant="label">
          Prescriptions
        </Typography>
        {drugs.map((drug) => (
          <div key={drug.id} className={styles.drug}>
            <Typography
              component="p"
              variant="h3"
              className={styles.drug__name}
            >
              {drug.name}
            </Typography>
            <Typography component="p" variant="label">
              {drug.strength}
            </Typography>
            <ScriptStatusSelect
              name={`drug-${drug.id}`}
              status={drug.status}
              onChange={onChangeStatus}
            />
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

export default Prescriptions;
