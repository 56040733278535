import Typography from "components/Typography";
import Card from "components/Card";
import CardContent from "components/CardContent";

import styles from "../RequestDetails.module.scss";

interface Props {
  doctor: {
    name: string;
    phone: string;
  };
  insurance: {
    name: string;
    logo: string;
  };
  showInsurance: () => void;
}

function DoctorDetails({ doctor, insurance, showInsurance }: Props) {
  return (
    <Card>
      <CardContent>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Doctor
          </Typography>
          <Typography component="p" variant="h3">
            {doctor.name}
          </Typography>
          <Typography component="p" variant="h3">
            {doctor.phone}
          </Typography>
        </div>
      </CardContent>
      <CardContent>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Insurance
          </Typography>
          <div className={styles.insurance}>
            <img
              className={styles.insurance__logo}
              alt="insurance"
              src={insurance.logo}
            />
            <Typography component="p" variant="h4">
              {insurance.name}
            </Typography>
            <div className={styles.insurance__edit_wrapper}>
              <Typography
                className={styles.insurance__edit}
                component="span"
                variant="a"
                onClick={showInsurance}
              >
                See card
              </Typography>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default DoctorDetails;
