import { useState } from "react";

function useSort(defaultColumn: string) {
  const [sortBy, setSortBy] = useState(defaultColumn);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  // to use in request queries to backend
  const order = `${sortOrder === "desc" ? "-" : ""}${sortBy}`;

  function onSort(sortBy: string, sortOrder: "asc" | "desc") {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  }

  return {
    sortBy,
    sortOrder,
    order,
    onSort,
  };
}

export default useSort;
