import Typography from "components/Typography";
import Card from "components/Card";
import CardContent from "components/CardContent";

import { Drug } from "types";

import styles from "../RequestDetails.module.scss";

interface Props {
  drugs: Drug[];
}

function Otcs({ drugs }: Props) {
  if (drugs.length === 0) return null;

  return (
    <Card>
      <CardContent px="sm" pt="md" pb="sm">
        <Typography component="p" variant="label">
          OTCs
        </Typography>
        {drugs.map((drug) => (
          <div key={drug.id} className={styles.otc}>
            <Typography
              component="p"
              variant="h3"
              className={styles.drug__name}
            >
              {drug.name}
            </Typography>
            <Typography component="p" variant="label">
              {drug.strength}
            </Typography>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

export default Otcs;
