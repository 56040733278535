import cn from "classnames";

import { ScriptStatus as Status } from "types";

import styles from "./ScriptStatus.module.scss";

interface Props {
  status: Status;
}

const StatusMapping: Record<Status, string> = {
  missing_transfer: "Missing transfer",
  completed: "Transfer completed",
  no_script_available: "No script available",
  cancel_script: "Cancel script",
};

function ScriptStatus({ status }: Props) {
  return (
    <div className={cn(styles.root, styles[status])}>
      <div className={styles.icon}>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.724 10.7145L11.9425 8.4485C12.128 8.259 12.1245 7.9555 11.9355 7.7695C11.746 7.584 11.442 7.5875 11.2565 7.777L9.045 10.036L6.139 7.13H6.6785C7.817 7.13 8.7435 6.2035 8.7435 5.065C8.7435 3.9265 7.817 3 6.6785 3H4.98C4.715 3 4.5 3.215 4.5 3.48V6.65C4.5 6.65 4.5 6.65 4.5 6.6505V10.491C4.5 10.756 4.715 10.971 4.98 10.971C5.245 10.971 5.46 10.756 5.46 10.491V7.809L8.3735 10.7225L6.2085 12.934C6.023 13.1235 6.0265 13.427 6.2155 13.6125C6.309 13.704 6.43 13.7495 6.551 13.7495C6.6755 13.7495 6.8 13.7015 6.894 13.605L9.0515 11.401L11.259 13.6085C11.353 13.7025 11.4755 13.749 11.5985 13.749C11.7215 13.749 11.844 13.702 11.938 13.6085C12.1255 13.421 12.1255 13.117 11.938 12.93L9.724 10.7145ZM7.784 5.0645C7.784 5.674 7.2885 6.1695 6.679 6.1695H5.4605V3.9595H6.679C7.288 3.9595 7.784 4.455 7.784 5.0645Z"
            fill="#132143"
          />
        </svg>
      </div>
      {StatusMapping[status]}
    </div>
  );
}

export default ScriptStatus;
