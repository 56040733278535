import { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import styles from "./ListTabs.module.scss";

interface Tab {
  title: string;
  path?: string;
  value?: string; // must be mandatory
}

interface Props {
  className?: string;
  tabs: Tab[];
  activeTabValue?: string;
  onChange?: (nextTabValue: string, prevTabValue: string) => void;
}

function ListTabs({ className, tabs, activeTabValue, onChange }: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className={cn(styles.root, className)}>
      {tabs.map((tab, index) => {
        if (tab.path) {
          return (
            <NavLink
              to={tab.path}
              className={styles.tab}
              activeClassName={styles["--active"]}
              exact
            >
              {tab.title}
            </NavLink>
          );
        }
        return (
          <span
            key={tab.title}
            className={cn(styles.tab, {
              [styles["--active"]]:
                tab.value !== undefined
                  ? tab.value === activeTabValue
                  : index === activeTabIndex,
            })}
            onClick={() =>
              onChange
                ? onChange(tab.value || "", activeTabValue || "")
                : setActiveTabIndex(index)
            }
          >
            {tab.title}
          </span>
        );
      })}
    </div>
  );
}

export default ListTabs;
