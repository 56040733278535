import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { formatISO } from "date-fns";
import api from "api";
import useCurrentUser from "hooks/useCurrentUser";
import { Note, RequestDetails } from "types";
import { getFullName } from "helpers/user";
import useCachedParams from "./useCachedParams";

function useCreateNote() {
  const { id } = useParams<{ id: string }>();
  const currentUser = useCurrentUser();

  const queryClient = useQueryClient();
  const { order, status } = useCachedParams();

  const createNote = useMutation(
    "create_note",
    async (text: string): Promise<string> => {
      const { data } = await api.post<{ text: string }>(
        `/requests/${id}/note/`,
        {
          text,
        }
      );
      return data.text;
    },
    {
      onMutate: async (text) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["request_details", id, order, status]);

        // Snapshot the previous value
        const previousRequest = queryClient.getQueryData<RequestDetails>([
          "request_details",
          id,
          order,
          status,
        ]);

        const newNote: Note = {
          id: Date.now(),
          text,
          author: getFullName(currentUser),
          avatar: currentUser.avatar,
          created_at: formatISO(Date.now()),
        };

        const nextRequest: RequestDetails | undefined = previousRequest
          ? {
              ...previousRequest,
              notes: [newNote, ...previousRequest.notes],
            }
          : undefined;

        // Optimistically update to the new value
        queryClient.setQueryData(
          ["request_details", id, order, status],
          nextRequest
        );

        // Return a context object with the snapshotted value
        return { previousRequest };
      },

      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, _text, context?: { previousRequest?: RequestDetails }) => {
        queryClient.setQueryData(
          ["request_details", id, order, status],
          context?.previousRequest
        );
      },

      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(["request_details", id, order, status]);
      },
    }
  );
  return createNote;
}

export default useCreateNote;
