import { useCallback, useEffect, useRef, useState } from "react";

import { Order } from "types";
import { addressToString } from "helpers/address";

import { calculateAndDisplayRoute } from "./helpers";
import { defaultOptions } from "./constants";

import { MapProps } from "./Map";

import { useStatsStore } from "../../stores";

export default function useMap({
  orders,
  start_location,
  end_location,
  display,
}: MapProps) {
  const ref = useRef(null);
  const [map, setMap] = useState<google.maps.Map>();

  const markers = useRef<google.maps.Marker[]>([]);

  const directionsService = useRef(new google.maps.DirectionsService());
  const directionsDisplay = useRef(
    new google.maps.DirectionsRenderer({ suppressMarkers: true })
  );

  const setDistance = useStatsStore(
    useCallback((state) => state.setDistance, [])
  );

  useEffect(
    function () {
      if (ref.current && !map) {
        setMap(new window.google.maps.Map(ref.current, defaultOptions));
      }
    },
    [ref, map]
  );

  useEffect(
    function () {
      if (map) directionsDisplay.current.setMap(map);
    },
    [directionsDisplay, map]
  );

  const calculateRoute = useCallback(
    async function (
      orders: Order[],
      start_location: string,
      end_location: string,
      display?: boolean
    ) {
      directionsDisplay.current.setMap(null);
      markers.current.forEach((m) => m.setMap(null));

      if (!map || !display) return;

      const mapPoints = orders.map((o) => addressToString(o.delivery_address));

      const { markers: result, distance } = await calculateAndDisplayRoute(
        directionsService.current,
        directionsDisplay.current,
        mapPoints,
        start_location,
        end_location
      );

      markers.current = result;
      setDistance(distance);

      directionsDisplay.current.setMap(map);
      markers.current.forEach((m) => m.setMap(map));
    },
    [directionsService, directionsDisplay, map, markers, setDistance]
  );

  useEffect(
    function () {
      calculateRoute(orders, start_location, end_location, display);
    },
    [orders, start_location, end_location, display, calculateRoute]
  );

  return { ref };
}
