import ReactModal, { Props as ReactModalProps } from "react-modal";
import cn from "classnames";

import closeIcon from "./icons/close.svg";

import styles from "./Modal.module.scss";

interface Props extends ReactModalProps {
  onClose?: () => void;
}

function Modal({
  overlayClassName,
  className,
  children,
  onClose,
  ...props
}: Props) {
  return (
    <ReactModal
      overlayClassName={cn(styles.overlay, overlayClassName)}
      className={cn(styles.modal, className)}
      shouldCloseOnOverlayClick
      {...props}
    >
      <img
        className={styles.close_icon}
        alt="close"
        src={closeIcon}
        onClick={onClose}
      />
      {children}
    </ReactModal>
  );
}

export default Modal;
