import { ChangeEventHandler } from "react";
import { capitalize } from "lodash";

import CheckboxField from "components/CheckboxField";
import Typography from "components/Typography";

import { Order } from "types";

import timer from "../../icons/timer.svg";

import styles from "./Ticket.module.scss";

interface Props extends Order {
  index?: number;
  arrival_time?: string;
  selected?: boolean;
  onChangeCheckbox?: ChangeEventHandler<HTMLInputElement>;
}

function Ticket({
  index,
  id,

  new_leaf_order,
  delivery_address,
  arrival_time,
  selected,
  onChangeCheckbox,
}: Props) {
  return (
    <div className={styles.root}>
      {index && <span className={styles.index}>{index}</span>}
      <CheckboxField
        name={`checkbox-${id}`}
        className={styles.checkbox}
        label={`#${new_leaf_order}`}
        checked={selected}
        onChange={onChangeCheckbox}
      />

      <Typography className={styles.address} variant="p">
        {delivery_address.address_line_1} {delivery_address.address_line_2}{" "}
        <br />
        {delivery_address.city}, {delivery_address.state}{" "}
        {delivery_address.zipcode}, (
        {capitalize(delivery_address.location?.replaceAll("_", " "))})
      </Typography>
      {arrival_time && (
        <span className={styles.delivery_time}>
          <img alt="timer icon" src={timer} />
          {arrival_time.split(":").slice(0, 2).join(":")}
        </span>
      )}
    </div>
  );
}

export default Ticket;
