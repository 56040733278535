function IconRx() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.836 15.3218L17.1637 11.9227C17.442 11.6385 17.4367 11.1833 17.1532 10.9043C16.869 10.626 16.413 10.6313 16.1348 10.9155L12.8175 14.304L8.4585 9.945H9.26775C10.9755 9.945 12.3653 8.55525 12.3653 6.8475C12.3653 5.13975 10.9755 3.75 9.26775 3.75H6.72C6.3225 3.75 6 4.0725 6 4.47V9.225C6 9.225 6 9.225 6 9.22575V14.9865C6 15.384 6.3225 15.7065 6.72 15.7065C7.1175 15.7065 7.44 15.384 7.44 14.9865V10.9635L11.8102 15.3337L8.56275 18.651C8.2845 18.9353 8.28975 19.3905 8.57325 19.6687C8.7135 19.806 8.895 19.8743 9.0765 19.8743C9.26325 19.8743 9.45 19.8022 9.591 19.6575L12.8273 16.3515L16.1385 19.6628C16.2795 19.8038 16.4633 19.8735 16.6477 19.8735C16.8322 19.8735 17.016 19.803 17.157 19.6628C17.4383 19.3815 17.4383 18.9255 17.157 18.645L13.836 15.3218ZM10.926 6.84675C10.926 7.761 10.1827 8.50425 9.2685 8.50425H7.44075V5.18925H9.2685C10.182 5.18925 10.926 5.9325 10.926 6.84675Z"
        fill="#222222"
      />
    </svg>
  );
}

export default IconRx;
