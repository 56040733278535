import classNames from "classnames";
import { forwardRef } from "react";

import styles from "./Input.module.scss";

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  icon?: React.ReactNode;
  hasError?: boolean;
  size?: "sm" | "md" | "lg";
}

const Input = forwardRef<HTMLInputElement, Props>(function (
  { className, icon, hasError, size, ...props },
  ref
) {
  return (
    <div className={classNames(styles.root, styles[`--${size}`])}>
      {icon && <div className={styles.icon_wrapper}>{icon}</div>}
      <input
        ref={ref}
        className={classNames(styles.input, className, styles[`--${size}`], {
          [styles[`--with-icon`]]: Boolean(icon),
          [styles[`--with-error`]]: hasError,
        })}
        {...props}
      />
    </div>
  );
});

Input.defaultProps = {
  size: "md",
};

export default Input;
