import Button from "components/Button";
import { ButtonColor } from "components/Button/Button";
import Modal from "components/Modal";
import React from "react";

import styles from "./ConfirmModal.module.scss";

interface Props {
  isOpen: boolean;
  children?: React.ReactNode;
  confirmButtonText: string;
  confirmButtonColor: ButtonColor;
  confirmButtonIsDisabled: boolean;
  onClose: () => void;
  onConfirm: () => any;
}

function ConfirmationModal({
  isOpen,
  children,
  confirmButtonText,
  confirmButtonColor,
  confirmButtonIsDisabled,
  onClose,
  onConfirm,
}: Props) {
  return (
    <Modal className={styles.root} isOpen={isOpen} onClose={onClose}>
      {children}
      <div className={styles.actions}>
        <Button color="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color={confirmButtonColor}
          onClick={onConfirm}
          disabled={confirmButtonIsDisabled}
        >
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  confirmButtonText: "Confirm",
  confirmButtonColor: "green",
  confirmButtonIsDisabled: false,
};

export default ConfirmationModal;
