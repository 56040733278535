import Button from "components/Button";
import DataTable from "components/DataTable";
import ListActionsWrapper from "components/ListActionsWrapper";
import ListWrapper from "components/ListWrapper";
import SearchInput from "components/SearchInput";
import PageHeader from "components/PageHeader";
import Typography from "components/Typography";
import Filter from "components/Filter";

import useEmployees from "./useEmployees";

import styles from "../Settings.module.scss";
import { DataTableSchemaItem } from "types";

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: "name",
    header: "Employee name",
    sortable: true,
  },
  {
    dataKey: "phone",
    header: "Phone number",
    sortable: true,
  },
  {
    dataKey: "role",
    header: "Employee status",
    sortable: true,
  },
  { dataKey: "view", header: "" },
];

function Employees() {
  const {
    filterOptions,
    filterValue,

    onChangeFilter,

    ...dataTableProps
  } = useEmployees();

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Employees</Typography>
        <Button to="/settings/employees/new" component="a" color="green">
          New Employee
        </Button>
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput size="sm" />
          <Filter
            size="sm"
            options={filterOptions}
            value={filterValue}
            onChange={onChangeFilter}
          />
        </ListActionsWrapper>
        <DataTable schema={SCHEMA} {...dataTableProps} />
      </ListWrapper>
    </div>
  );
}

export default Employees;
