import { useHistory, useRouteMatch } from "react-router";

import IconButton from "components/IconButton";

import styles from "./Back.module.scss";

function Back() {
  const { path } = useRouteMatch();
  const { push } = useHistory();
  const backUrl = path.replace(/\/:\w*/, "");
  const goBack = () => {
    push(backUrl);
  };

  return (
    <IconButton icon="arrow_left" className={styles.root} onClick={goBack} />
  );
}

export default Back;
