import React, { useMemo, useState } from "react";
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "react-query";
import { format } from "date-fns";
import { capitalize, flatten } from "lodash";
import { parseISO } from "date-fns";

import StatusBadge from "components/StatusBadge";
import TableAction from "components/TableAction";

import api from "api";
import { ListResponse, StatusColor, Order } from "types";
import { getFullName } from "helpers/user";
import Typography from "components/Typography";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";

type TabValue = "pickup" | "delivered,canceled";

const colorMapping: Record<string, StatusColor> = {
  pickup: "iris_dark",
  delivered: "gray",
  canceled: "critical",
};

async function getInstorePickups({
  queryKey,
  pageParam,
}: QueryFunctionContext<string[]>) {
  const [, status] = queryKey;
  const { limit, offset } = getLimitOffset(pageParam);

  const { data } = await api.get<ListResponse<Order>>("/orders/pickup/", {
    params: { status, limit, offset },
  });

  return data;
}

function useInstorePickups() {
  const [activeId, setActiveId] = useState(0);
  const [isConfirming, setIsConfirming] = useState(false);
  const [status, setStatus] = useState<TabValue>("pickup");
  const {
    data: orders,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["instore_pickup", status], getInstorePickups, {
    getNextPageParam,
  });
  const queryClient = useQueryClient();
  const complete = useMutation(
    "complete_order",
    async function (order_id: number) {
      await api.post(`/orders/${order_id}/complete/`);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["instore_pickup", "pickup"]);
        queryClient.invalidateQueries(["instore_pickup", "delivered,canceled"]);
      },
    }
  );

  function onChangeTab(tab: string) {
    setStatus(tab as TabValue);
  }

  function openConfirm(event: React.MouseEvent<HTMLDivElement>) {
    const dataId = event.currentTarget.getAttribute("data-id");

    if (Number(dataId)) {
      setActiveId(Number(dataId));
    }
  }

  function closeConfirm() {
    setActiveId(0);
  }

  async function confirm() {
    setIsConfirming(true);
    await complete.mutate(activeId);
    setIsConfirming(false);
  }

  const data = useMemo(() => {
    if (!orders) return [];

    const results = flatten(orders.pages.map((page) => page.results));

    return results.map((o) => ({
      ...o,
      new_leaf_order: (
        <Typography variant="strong">#{o.new_leaf_order}</Typography>
      ),
      created_at: format(parseISO(o.created_at), "dd/MM/yy h:mmaaa"),
      customer: getFullName(o.customer),
      status: (
        <StatusBadge color={colorMapping[o.status]}>
          {o.status === "pickup" ? "In-store pickup" : capitalize(o.status)}
        </StatusBadge>
      ),
      confirm: o.status === "pickup" && (
        <TableAction data-id={o.id} onClick={openConfirm}>
          CONFIRM
        </TableAction>
      ),
    }));
  }, [orders]);

  return {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,

    isConfirmOpen: Boolean(activeId),
    isConfirming,
    activeTab: status,

    fetchNextPage,

    onChangeTab,

    closeConfirm,
    confirm,
  };
}

export default useInstorePickups;
