import { Status, StatusColor } from "types";

export const mapStatusColor: Record<Status, StatusColor> = {
  new: "default",
  in_progress: "gray",
  unresponsive: "iris",
  completed: "green",
};

export const mapStatusText: Record<Status, string> = {
  new: "New Request",
  in_progress: "In progress",
  unresponsive: "Unresponsive",
  completed: "Completed",
};
