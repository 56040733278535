import { Controller } from "react-hook-form";

import Typography from "components/Typography";
import PageHeader from "components/PageHeader";
import Button from "components/Button";
import FormActionGroup from "components/FormActionGroup";
import FormGroup from "components/FormGroup";
import TextField from "components/TextField";
import SelectField from "components/SelectField";
import ListWrapper from "components/ListWrapper";
import CardContent from "components/CardContent";

import states from "constants/states";
import { validateEmail } from "utils/validators";

import useEmployeeForm from "./useEmployeeForm";

import styles from "./EmployeeForm.module.scss";
import roles from "constants/roles";
import { Fragment } from "react";
import FileUpload from "components/FileUpload";

function EmployeeForm() {
  const {
    employeeId,
    options,
    submitting,
    errors,
    control,
    isDriver,
    license_files,
    register,
    handleFileUpload,
    handleSubmit,
  } = useEmployeeForm();

  return (
    <form onSubmit={handleSubmit}>
      <PageHeader>
        <Typography variant="h2">
          {employeeId ? "Edit Employee" : "New Employee"}
        </Typography>
        <FormActionGroup>
          <Button to="/settings/employees" component="a" color="gray">
            Cancel
          </Button>
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register("first_name", { required: "Required" })}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Surname"
              {...register("last_name", { required: "Required" })}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register("email", {
                required: "Required",
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="Phone number"
              {...register("phone", {
                required: "Required",
              })}
              error={errors.phone?.message}
            />
          </FormGroup>

          <FormGroup columns={2}>
            <TextField
              label="Address line 1"
              placeholder="Street"
              {...register("address_line_1", {
                required: "Required",
              })}
              error={errors.address_line_1?.message}
            />
            <TextField
              label="Address line 2"
              placeholder="Apartment"
              {...register("address_line_2")}
              error={errors.address_line_2?.message}
            />
          </FormGroup>

          <FormGroup columns={3}>
            <TextField
              label="City"
              placeholder="City name"
              {...register("city", {
                required: "Required",
              })}
              error={errors.city?.message}
            />
            <TextField
              label="Zipcode"
              placeholder="Zipcode"
              {...register("zipcode", {
                required: "Required",
              })}
              error={errors.zipcode?.message}
            />
            <Controller
              name="state"
              control={control}
              rules={{ required: "Required" }}
              render={({ field, fieldState }) => (
                <SelectField
                  label="State"
                  placeholder="Select a state"
                  options={states}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </FormGroup>

          <Typography className={styles.header} variant="h3">
            Account
          </Typography>
          <div className={styles.account_warning}>
            Please call the driver and inform them of their login information
            and instructions to download the driver app
          </div>

          <FormGroup columns={3}>
            <TextField
              label="Username"
              placeholder="Username"
              {...register("username", {
                required: "Required",
              })}
              error={errors.username?.message}
            />
            <TextField
              label="Password"
              placeholder="Password"
              {...register("password", {
                required: "Required",
              })}
              error={errors.password?.message}
            />
            <TextField
              label="Password confirmation"
              placeholder="Password confirmation"
              {...register("password_confirmation", {
                required: "Required",
              })}
              error={errors.password_confirmation?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <Controller
              name="role"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectField
                  label="Employee Type"
                  placeholder="Employee Type"
                  options={roles}
                  {...field}
                />
              )}
            />
          </FormGroup>

          {isDriver && (
            <Fragment>
              <Typography className={styles.header} variant="h3">
                If Driver
              </Typography>
              <FormGroup columns={1}>
                <Controller
                  name="vehicle"
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      label="Assigned truck"
                      placeholder="No assigned truck"
                      options={options}
                      {...field}
                    />
                  )}
                />
              </FormGroup>

              <div className={styles.section_header}>Driver’s license</div>

              <FormGroup columns={2}>
                <FileUpload
                  label="Please upload both sides of the document"
                  onChange={handleFileUpload}
                  files={license_files}
                />

                <div>
                  <FormGroup columns={2}>
                    <TextField
                      label="Document Number"
                      placeholder="S111-123-45-567-8"
                      {...register("document_number")}
                    />

                    <Controller
                      name="document_state"
                      control={control}
                      render={({ field }) => (
                        <SelectField
                          label="Document Class"
                          placeholder="Select a class"
                          options={states}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      name="issue_state"
                      control={control}
                      render={({ field }) => (
                        <SelectField
                          label="Issue state"
                          placeholder="Select a state"
                          options={states}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              </FormGroup>
            </Fragment>
          )}
        </CardContent>
      </ListWrapper>
    </form>
  );
}

export default EmployeeForm;
