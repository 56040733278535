import { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import cn from "classnames";

import Sidebar from "components/Sidebar";

import Support from "pages/Support";
import DeliveryPlanner from "pages/DeliveryPlanner";
import InstorePickups from "pages/InstorePickups";
import Deliveries from "pages/Deliveries";
import Settings from "pages/Settings";
import RequestDetails from "pages/RequestDetails";
import Requests from "pages/Requests";
import EmployeeForm from "pages/EmployeeForm";
import VehicleForm from "pages/VehicleForm";
import UserDetails from "pages/UserDetails";
import UserForm from "pages/UserForm";

import { CustomRouteProps } from "components/CustomRoute";
import useUi from "hooks/useUi";

const mainRoutes: CustomRouteProps[] = [
  { path: "/support", component: Support },
  { path: "/deliveries/delivery_planner", component: DeliveryPlanner },
  { path: "/deliveries/in-store_pickups", component: InstorePickups },
  { path: "/deliveries", component: Deliveries },
  { path: "/settings/employees/new", component: EmployeeForm },
  { path: "/settings/employees/:employeeId", component: EmployeeForm },
  { path: "/settings/vehicles/new", component: VehicleForm },
  { path: "/settings/vehicles/:vehicleId", component: VehicleForm },
  { path: "/settings/users/:userId/edit", component: UserForm },
  { path: "/settings/users/:userId", component: UserDetails },
  { path: "/settings", component: Settings },
  { path: "/requests/:id", component: RequestDetails },
  { path: "/requests", component: Requests },
];

function MainApp() {
  const { sidebarExtended, fullsizeContent } = useUi();
  return (
    <Fragment>
      <Sidebar />
      <div
        className={cn("content", {
          "--sidebar-extended": !sidebarExtended,
          "--fullsize": fullsizeContent,
        })}
      >
        <Switch>
          {mainRoutes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Redirect to="/requests" />
        </Switch>
      </div>
    </Fragment>
  );
}

export default MainApp;
