import { format, parse } from "date-fns";
import classNames from "classnames";

import Typography from "components/Typography";
import Card from "components/Card";
import CardContent from "components/CardContent";

import { Customer } from "types";
import { getFullName } from "helpers/user";

import IdentificationCard from "./icons/IdentificationCard.svg";

import styles from "../RequestDetails.module.scss";

interface Props {
  pharmacy: {
    name: string;
    phone: string;
    address: string;
  };
  customer: Customer;
}

function CustomerDetails({ pharmacy, customer }: Props) {
  return (
    <Card>
      <CardContent dark>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Pharmacy
          </Typography>
          <Typography component="p" variant="h2">
            {pharmacy.name}
          </Typography>
        </div>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Telephone numbers
          </Typography>
          <Typography component="p" variant="h3">
            {pharmacy.phone}
          </Typography>
        </div>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Address
          </Typography>
          <Typography component="p" variant="h3">
            {pharmacy.address}
          </Typography>
        </div>
      </CardContent>
      <CardContent>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Customer
          </Typography>
          <Typography className={styles.fullname} component="p" variant="h2">
            {getFullName(customer)}
          </Typography>
          <img
            className={styles.identification_card}
            alt="Identification card"
            src={IdentificationCard}
          />
        </div>
        <div className={classNames(styles.info, styles["--borderless"])}>
          <Typography component="p" variant="label">
            Contact Information
          </Typography>
          <Typography component="p" variant="h3">
            {customer.phone}
          </Typography>
        </div>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Date of birth
          </Typography>
          <Typography component="p" variant="h3">
            {format(
              parse(customer.birthday, "yyyy-MM-dd", new Date()),
              "MMMM, dd, yyyy"
            )}
          </Typography>
        </div>
        <div className={styles.info}>
          <Typography variant="a" href={`mailto:${customer.email}`}>
            {customer.email}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default CustomerDetails;
