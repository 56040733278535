import { useMemo } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";

import api from "api";
import { DataItem, ListResponse, Vehicle } from "types";
import { getFullName } from "helpers/user";
import ViewDetails from "components/ViewDetails";
import useSort from "hooks/useSort";
import { getLimitOffset, getNextPageParam } from "helpers/react_query";
import { flatten } from "lodash";

async function getVehicles({ queryKey, pageParam }: QueryFunctionContext) {
  const [, order] = queryKey;
  const { limit, offset } = getLimitOffset(pageParam);

  const { data } = await api.get<ListResponse<Vehicle>>("/vehicles/", {
    params: { order, limit, offset },
  });

  return data;
}

function mapVehicles(vehiles: Vehicle[]): DataItem[] {
  return vehiles.map((v) => ({
    ...v,
    driver: getFullName(v.driver) || "-",
    view: <ViewDetails to={`/settings/vehicles/${v.id}`} />,
  }));
}

function useVehicles() {
  const { sortBy, sortOrder, order, onSort } = useSort("nickname");
  const {
    data: vehicles,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["vehicles", order], getVehicles, { getNextPageParam });

  const data = useMemo(
    function () {
      if (!vehicles) return [];

      const results = flatten(vehicles.pages.map((page) => page.results));

      return mapVehicles(results);
    },
    [vehicles]
  );

  return {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,

    sortBy,
    sortOrder,

    fetchNextPage,
    onSort,
  };
}

export default useVehicles;
