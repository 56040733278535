import { Order } from "types";

import styles from "./Map.module.scss";
import useMap from "./useMap";

export interface MapProps {
  orders: Order[];
  start_location: string;
  end_location: string;
  display?: boolean;
}

export default function Map(props: MapProps) {
  const { ref } = useMap(props);

  return <div ref={ref} className={styles.root}></div>;
}
