import DataTable from "components/DataTable";
import ListActionsWrapper from "components/ListActionsWrapper";
import ListTabs from "components/ListTabs";
import ListWrapper from "components/ListWrapper";
import PageHeader from "components/PageHeader";
import SearchInput from "components/SearchInput";
import Typography from "components/Typography";

import styles from "../Settings.module.scss";
import useRoutes from "./useRoutes";

const SCHEMA = [
  {
    dataKey: "number",
    header: "Route ID",
  },
  {
    dataKey: "nickname",
    header: "Nickname",
  },
  {
    dataKey: "created_at",
    header: "Date & Time",
    sortable: true,
  },
  {
    dataKey: "vehicle",
    header: "Vehicle",
    sortable: true,
  },
  {
    dataKey: "driver",
    header: "Driver",
    sortable: true,
  },
  {
    dataKey: "status",
    header: "Route status",
    sortable: true,
  },
  {
    dataKey: "view",
    header: "",
  },
];

const tabs = [
  { title: "All", value: "all" },
  { title: "Active", value: "active" },
  { title: "Complete", value: "completed" },
];

function Routes() {
  const { activeTab, setActiveTab, ...dataTableProps } = useRoutes();

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Routes</Typography>
      </PageHeader>
      <ListWrapper>
        <ListTabs
          tabs={tabs}
          activeTabValue={activeTab}
          onChange={setActiveTab}
        />
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput size="sm" />
        </ListActionsWrapper>
        <DataTable schema={SCHEMA} {...dataTableProps} />
      </ListWrapper>
    </div>
  );
}

export default Routes;
