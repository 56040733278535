import create from "zustand";

const LOCATIONS = [
  "brooklyn",
  "manhattan",
  "queens",
  "staten_island",
  "bronx",
  "jersey_city",
];
const TIMES = ["morning", "afternoon", "evening"];

interface FilterStore {
  location: string[];
  time: string[];

  sort?: string;

  setFilters: (values: string[]) => void;
  setSort: (value?: string) => void;
}

export const useFilterStore = create<FilterStore>((set) => ({
  location: [],
  time: [],
  setFilters: (values) => {
    const location = values.filter((v) => LOCATIONS.includes(v));
    const time = values.filter((v) => TIMES.includes(v));

    set({ location, time });
  },
  setSort: (sort) => {
    set({ sort });
  },
}));
