import { format, parseISO } from "date-fns";

import Typography from "components/Typography";

import { File } from "types";

import styles from "./Note.module.scss";
import Avatar from "components/Avatar";

interface Props {
  text: string;
  created_at: string;
  author: string;
  avatar?: File;
}

function Note({ text, author, avatar, created_at }: Props) {
  return (
    <div className={styles.root}>
      <Typography variant="p">{text}</Typography>
      <div className={styles.bottom}>
        <div className={styles.author}>
          <Avatar name={author} file={avatar} size="sm" />
          <span className={styles.author__name}>{author}</span>
        </div>
        <span className={styles.datetime}>
          {format(parseISO(created_at), "MMMM d, h:mm aa")}
        </span>
      </div>
    </div>
  );
}

export default Note;
