import { forwardRef } from "react";

import Input, { InputProps } from "components/Input";

import FieldWrapper from "components/FieldWrapper";

interface Props extends InputProps {
  label?: string;
  error?: string;
}

const TextField = forwardRef<HTMLInputElement, Props>(function (
  { label, error, ...restProps },
  ref
) {
  return (
    <FieldWrapper label={label} error={error}>
      <Input ref={ref} {...restProps} hasError={Boolean(error)} />
    </FieldWrapper>
  );
});

export default TextField;
