import create from "zustand";

interface StatsStore {
  distance: number;
  setDistance: (d: number) => void;
}

export const useStatsStore = create<StatsStore>((set) => ({
  distance: 0,
  setDistance: (distance: number) => set({ distance }),
}));
