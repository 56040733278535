import Select from "components/Select";
import { useFilterStore } from "pages/DeliveryPlanner/stores";
import { useCallback } from "react";

import "./Sort.scss";

const options = [
  { value: "id", label: "Number" },
  { value: "location", label: "Location" },
  { value: "time", label: "Time Preference" },
];

function Sort() {
  const { sort, setSort } = useFilterStore(
    useCallback(
      (state) => ({
        sort: state.sort,
        setSort: state.setSort,
      }),
      []
    )
  );

  return (
    <Select
      className="DeliveryPlanner__Sort"
      placeholder="Sort by"
      isSearchable={false}
      value={options.find((o) => o.value === sort)}
      options={options}
      onChange={(newValue) => {
        const value = newValue?.value ? String(newValue?.value) : undefined;
        setSort(value);
      }}
    />
  );
}

export default Sort;
