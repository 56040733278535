import React from "react";
import cn from "classnames";

import Typography from "components/Typography";

import styles from "./FieldWrapper.module.scss";

interface Props {
  label?: string;
  error?: string;
  children: React.ReactNode;
}

function FieldWrapper({ label, error, children }: Props) {
  return (
    <div className={cn(styles.root)}>
      <label className={styles.label}>{label}</label>
      {children}
      {error && (
        <Typography className={styles.error} variant="p">
          {error}
        </Typography>
      )}
    </div>
  );
}

export default FieldWrapper;
