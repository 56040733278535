import { findLast } from "lodash";
import { useQueryClient } from "react-query";

function useCachedParams() {
  const queryClient = useQueryClient();
  const queryCach = queryClient.getQueryCache().getAll();
  const [, order, status] =
    (findLast(queryCach, (q) => q.queryKey[0] === "requests")
      ?.queryKey as string[]) || [];

  return { order, status };
}

export default useCachedParams;
