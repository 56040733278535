import { User } from "types";
import { addressToString } from "./address";

export function getFullName(user?: {
  first_name?: string;
  last_name?: string;
}) {
  return user ? [user.first_name, user.last_name].join(" ").trim() : "";
}

export function getAddress(user: User) {
  return addressToString({
    address_line_1: user.address_line_1,
    address_line_2: user.address_line_2,
    city: user.city,
    state: user.state,
    zipcode: user.zipcode,
  });
}
