import { useMutation, useQueryClient } from "react-query";

import api from "api";
import { RequestDetails, ScriptStatus } from "types";
import { useParams } from "react-router-dom";
import useCachedParams from "./useCachedParams";

function useUpdateSciptStatus() {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { order, status } = useCachedParams();

  const updateScriptStatus = useMutation(
    "update_script_status",
    async ({ drugId, status }: { drugId: number; status: ScriptStatus }) => {
      console.log("updateScriptStatus", drugId, status);

      const { data } = await api.post<{ status: ScriptStatus }>(
        `/requests/${id}/prescriptions/${drugId}/status/`,
        { status }
      );
      return { ...data, drugId };
    },
    {
      onMutate: async ({ drugId, status: drugStatus }) => {
        await queryClient.cancelQueries(["request_details", id, order, status]);

        const previousRequest = queryClient.getQueryData<RequestDetails>([
          "request_details",
          id,
          order,
          status,
        ]);

        if (!previousRequest) throw Error("Request is `undefined`");

        const nextRequest: RequestDetails = {
          ...previousRequest,
          prescriptions: previousRequest.prescriptions.map((drug) =>
            drug.id === drugId ? { ...drug, status: drugStatus } : drug
          ),
        };

        queryClient.setQueryData(
          ["request_details", id, order, status],
          nextRequest
        );

        return { previousRequest };
      },
      onSettled: () => {
        queryClient.invalidateQueries(["request_details", id, order, status]);
      },
    }
  );
  return updateScriptStatus;
}

export default useUpdateSciptStatus;
