import Select, { Props as SelectProps } from "react-select";
import { ScriptStatus, SelectOption } from "types";

import "./ScriptStatusSelect.scss";

export type ScriptStatusOption = SelectOption<ScriptStatus>;

interface Props extends SelectProps<ScriptStatusOption, false> {
  status: ScriptStatus;
}

const OPTIONS: SelectOption<ScriptStatus>[] = [
  { value: "missing_transfer", label: "Missing transfer" },
  { value: "completed", label: "Transfer completed" },
  { value: "no_script_available", label: "No script available" },
  { value: "cancel_script", label: "Cancel script" },
];

function ScriptStatusSelect({ status, isSearchable, ...restProps }: Props) {
  const selectedValue = OPTIONS.find((o) => o.value === status);
  return (
    <Select
      classNamePrefix="ScriptStatusSelect"
      className={status}
      options={OPTIONS}
      value={selectedValue}
      isSearchable={false}
      {...restProps}
    />
  );
}

export default ScriptStatusSelect;
