import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { add, format, parseISO } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";

import useUi from "hooks/useUi";
import { Order, Vehicle, User, RouteStatus } from "types";
import api from "api";
import { wait } from "utils/dummy";

import { defaultRoute, METERS_IN_MILE } from "./constants";
import {
  getOrders,
  getVehicles,
  getRouteById,
  getRequestData,
} from "./helpers";
import { useFilterStore, useStatsStore } from "./stores";

export interface Route {
  id: number;
  nickname?: string;
  driver?: User;
  vehicle?: Vehicle;
  start_time: string;
  start_location: string;
  end_location: string;
  orders: Order[];
  status: RouteStatus;
  total_time?: number;
}

function useDeliveryPlanner() {
  const { search } = useLocation();
  const { push } = useHistory();
  const routeId = useMemo(
    () => new URLSearchParams(search).get("id"),
    [search]
  );

  const { setSidebarExtended, setFullsizeContent } = useUi();
  const distance = useStatsStore(useCallback((state) => state.distance, []));
  const { location, time, sort } = useFilterStore(
    useCallback(
      (state) => ({
        location: state.location.join(","),
        time: state.time.join(","),
        sort: state.sort,
      }),
      []
    )
  );

  const queryClient = useQueryClient();
  const { data: vehicles } = useQuery("vehicles", getVehicles);
  const { data: orders } = useQuery(
    ["orders", location, time, sort],
    getOrders
  );
  const { data: route } = useQuery(["route", routeId], getRouteById, {
    initialData: defaultRoute,
    refetchOnWindowFocus: Boolean(routeId),
  });

  const updateRoute = useMutation(
    "update_route",
    async function (nextRoute: Route) {
      const nextRouteData = getRequestData({
        ...route,
        ...nextRoute,
      });

      // save changes if route is already created
      if (route?.id) {
        const { data } = await api.patch<Route>(
          `/routes/${route?.id}/`,
          nextRouteData
        );
        return data;
      }

      // just return changes if route is not created
      return {
        ...route,
        ...nextRoute,
      };
    },
    {
      onMutate: async (nextRoute) => {
        await queryClient.cancelQueries(["route", routeId]);

        const previousRoute = queryClient.getQueryData<Route>([
          "routes",
          routeId,
        ]);
        queryClient.setQueryData(["route", routeId], nextRoute);

        return { previousRoute };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["orders", location, time, sort]);
      },
      onSettled: (data) => {
        if (!data) {
          queryClient.invalidateQueries(["route", routeId]);
        }
      },
    }
  );

  const [selectedUnassignedOrders, setSelectedUnassignedOrders] = useState<
    number[]
  >([]);
  const [selectedAssignedOrders, setSelectedAssignedOrders] = useState<
    number[]
  >([]);

  const unassignedOrders = useMemo(
    function () {
      if (orders) {
        const routeOrders = route?.orders.map((o) => o.id) || [];

        return [...orders.results].filter((o) => !routeOrders.includes(o.id));
      }

      return [];
    },
    [orders, route]
  );

  useEffect(
    function () {
      setSidebarExtended(false);
      setFullsizeContent(true);

      return function () {
        setSidebarExtended(true);
        setFullsizeContent(false);
      };
    },
    [setSidebarExtended, setFullsizeContent]
  );

  function onChangeUnassignedCheckbox(event: ChangeEvent<HTMLInputElement>) {
    const id = Number(event.currentTarget.getAttribute("name")?.split("-")[1]);

    if (event.currentTarget.checked) {
      setSelectedUnassignedOrders([...selectedUnassignedOrders, id]);
    } else {
      setSelectedUnassignedOrders(
        selectedUnassignedOrders.filter((selected) => selected !== id)
      );
    }
  }

  function onChangeAssignedCheckbox(event: ChangeEvent<HTMLInputElement>) {
    const id = Number(event.currentTarget.getAttribute("name")?.split("-")[1]);

    if (event.currentTarget.checked) {
      setSelectedAssignedOrders([...selectedAssignedOrders, id]);
    } else {
      setSelectedAssignedOrders(
        selectedAssignedOrders.filter((selected) => selected !== id)
      );
    }
  }

  function onChangeRouteParams(
    start_location: string,
    end_location: string,
    currentVehicle?: Vehicle
  ) {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      start_location,
      end_location,
      vehicle: currentVehicle,
    });
  }

  function onChangeStartTime(start_time: string) {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      start_time: start_time,
    });
  }

  function assignSelected() {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      orders: [
        ...route.orders,
        ...unassignedOrders.filter((o) =>
          selectedUnassignedOrders.includes(o.id)
        ),
      ],
    });

    setSelectedUnassignedOrders([]);
  }

  function unassignSelected() {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      orders: route.orders.filter(
        (o) => !selectedAssignedOrders.includes(o.id)
      ),
    });

    queryClient.invalidateQueries(["orders", location, time, sort]);

    setSelectedAssignedOrders([]);
  }

  async function calculate() {
    let id = route?.id;

    // if route is not created, create it
    if (route && !route.id) {
      const { data } = await api.post<Route>("/routes/", getRequestData(route));
      id = data.id;
    }

    // initiate task to calculate the route
    await api.post(`/routes/${id}/calculate/`);
    let pendingRoute = route;

    do {
      await wait(5000);
      const { data } = await api.get<Route>(`/routes/${id}/`);
      pendingRoute = data;
      queryClient.setQueriesData(["route", routeId], data);
    } while (pendingRoute.status !== "processed");

    if (!routeId) {
      push({ search: `?id=${id}` });
    }
  }

  return {
    route: route || defaultRoute,
    currentVehicle: vehicles?.results.find((v) => v.id === route?.vehicle?.id),
    vehicles,

    unassignedOrders,

    selectedUnassignedOrders,
    selectedAssignedOrders,

    stats: {
      stops: route?.orders.length,
      distance: `${Math.round((distance * 10) / METERS_IN_MILE) / 10} mi`,
      total_time: route?.total_time
        ? `${Math.ceil(route.total_time / 60)}h ${route.total_time % 60}m`
        : "",
      end_time:
        route?.start_time && route.total_time
          ? format(
              add(parseISO(route.start_time), { minutes: route.total_time }),
              "h:mm aaa"
            )
          : "-:-- --",
    },

    isValid: Boolean(
      route && route.start_location && route.end_location && route.start_time
    ),

    onChangeUnassignedCheckbox,
    onChangeAssignedCheckbox,
    onChangeRouteParams,
    onChangeStartTime,

    assignSelected,
    unassignSelected,

    calculate,
  };
}

export default useDeliveryPlanner;
