import classNames from "classnames";

import ConfirmModal from "components/ConfirmModal";
import SelectField from "components/SelectField";
import TextField from "components/TextField";
import Typography from "components/Typography";

import styles from "./Actions.module.scss";
import useActions from "./useActions";

function Actions() {
  const {
    isValid,
    isCreated,
    deleteModalIsOpen,
    saveModalIsOpen,
    sendModalIsOpen,

    nickname,

    vehicleOptions,
    driverOptions,

    assignedVehicle,
    assignedDriver,

    onDelete,
    onSave,
    onSend,

    closeModals,

    confirmDelete,
    confirmSave,
    confirmSend,

    handleChangeNickname,
    handleChangeSendForm,
  } = useActions();

  return (
    <div className={styles.root}>
      <button
        className={classNames(styles.action_btn, styles["--delete"])}
        disabled={!isValid || !isCreated}
        onClick={onDelete}
      >
        <i className="fas fa-trash-alt"></i> Delete
      </button>
      <button
        className={classNames(styles.action_btn, styles["--save"])}
        disabled={!isValid}
        onClick={onSave}
      >
        <i className="fas fa-save"></i> Save
      </button>
      <button
        className={classNames(styles.action_btn, styles["--send"])}
        disabled={!isValid || !isCreated}
        onClick={onSend}
      >
        <i className="fas fa-paper-plane"></i> Send
      </button>

      <ConfirmModal
        isOpen={deleteModalIsOpen}
        onClose={closeModals}
        onConfirm={confirmDelete}
        confirmButtonText="Delete"
        confirmButtonColor="critical"
      >
        <Typography variant="h2">
          Do you want to delete current route?
        </Typography>
      </ConfirmModal>

      <ConfirmModal
        isOpen={saveModalIsOpen}
        onClose={closeModals}
        onConfirm={confirmSave}
        confirmButtonColor="blue"
      >
        <Typography variant="h2">Save route</Typography>
        <Typography variant="h4">Enter route nickname (optional)</Typography>
        <TextField
          label="Nickname"
          value={nickname}
          onChange={handleChangeNickname}
        />
      </ConfirmModal>

      <ConfirmModal
        isOpen={sendModalIsOpen}
        onClose={closeModals}
        onConfirm={confirmSend}
        confirmButtonIsDisabled={!assignedDriver || !assignedVehicle}
      >
        <Typography variant="h2">Send route</Typography>
        <Typography variant="h4">Who should we send this route to?</Typography>
        <SelectField
          label="Assigned vehicle"
          name="vehicle"
          value={assignedVehicle}
          options={vehicleOptions}
          onChange={handleChangeSendForm}
        />

        <SelectField
          label="Assigned driver"
          name="driver"
          value={assignedDriver}
          options={driverOptions}
          onChange={handleChangeSendForm}
        />
      </ConfirmModal>
    </div>
  );
}

export default Actions;
