import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, QueryFunctionContext } from "react-query";
import { format, parse } from "date-fns";

import { Customer } from "types";

import api from "api";
import states from "constants/states";

interface Option {
  label: string;
  value: string | number;
}

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: Option;
}

async function getUserById({ queryKey }: QueryFunctionContext) {
  const [, userId] = queryKey;

  if (!userId) return undefined;

  const { data } = await api.get<Customer>(`/customers/${userId}`);

  return data;
}

function useUserForm() {
  const { userId } = useParams<{ userId: string }>();
  const { push } = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValue>();

  const { data: user } = useQuery(["employee", userId], getUserById);

  useEffect(
    function () {
      if (user) {
        reset({
          ...user,
          state: states.find((state) => state.value === user.state),
        });
      }
    },
    [user, reset]
  );

  async function submit(data: FormValue) {
    setSubmitting(true);

    const user = {
      ...data,
      state: data.state.value,
      birthday: format(
        parse(data.birthday, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      ),
    };

    if (userId) {
      await api.patch(`/users/${userId}/`, user);
    } else {
      await api.post("/users/", user);
    }

    setSubmitting(false);
    push(userId ? `/settings/users/${userId}` : "/settings/users");
  }

  return {
    userId,
    submitting,
    errors,
    control,

    register,
    handleSubmit: handleSubmit(submit),
  };
}

export default useUserForm;
