import { last } from "lodash";

export function getFilename(url: string) {
  const filename = last(url.split("/"));

  if (!filename) return url;

  let [name, ext] = filename.split(".");

  if (name.length > 20) {
    name = `${name.slice(0, 8)}...${name.slice(name.length - 4)}`;
  }

  return [name, ext].join(".");
}
