import ReactSelect, { Props as ReactSelectProps } from "react-select";
import classNames from "classnames";

import { SelectOption } from "types";

import "./Select.scss";

export interface Props extends ReactSelectProps<SelectOption, false> {
  size: "sm" | "md" | "lg";
  variant?: "default" | "round";
}

const Select = function ({ size, className, variant, ...restProps }: Props) {
  return (
    <ReactSelect
      classNamePrefix="Select"
      className={classNames("Select", className, `--${size}`, `--${variant}`)}
      {...restProps}
    />
  );
};

Select.defaultProps = {
  size: "md",
  variant: "default",
};

export default Select;
